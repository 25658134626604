<loading-layout #loadingLayout>
    <div class="row" *ngIf="!loadingLayout.loading">
        <div class="col-md-5">
            <div class="row">
                <cui-section-headline>{{ "usuario.logros.resumen.titulo" | t }}</cui-section-headline>
                <div class="col-md-4">
                    <div class="resumen-container">
                        <div class="logros-container">
                            <div class="puntos-container">
                                <div class="puntos">
                                    {{ usuarioPlataforma?.experiencia || 0 }}
                                </div>
                                <div class="puntos-text">{{ "usuario.logros.resumen.puntos" | t }}</div>
                            </div>
                        </div>
                        <div class="nivel-container">
                            <div class="img-container">
                                <img *ngIf="nivel?.url_imagen" [src]="nivel.url_imagen" />
                                <img
                                    *ngIf="!nivel?.url_imagen"
                                    src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/logros/achievement_profile.png"
                                />
                                <div class="text-img">{{ nivel?.nivel | easyplaceholder: "Sin nivel" }}</div>
                            </div>
                            <div class="barra-nivel-container">
                                <ng-template #expTemplate>
                                    <div class="experiencia">
                                        <div>{{ "usuario.logros.resumen.prox_nivel" | t }}</div>
                                        <div>{{ nextNivel?.experiencia | easyplaceholder: "0" }}</div>
                                    </div>
                                </ng-template>
                                <div class="barra-exp">
                                    <a href="#" [tooltip]="expTemplate">
                                        <progressbar
                                            [animate]="false"
                                            [value]="experienciaNivelPer"
                                            type="success"
                                        ></progressbar>
                                    </a>
                                </div>
                                <div class="guia-container">
                                    <ng-template #tolTemplate>
                                        <div class="guia-niveles-container">
                                            <div class="guia-niveles" *ngFor="let nivel of niveles">
                                                <div>{{ nivel.nivel }}</div>
                                                <div>{{ nivel.experiencia }}</div>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <p [tooltip]="tolTemplate">
                                        {{ "usuario.logros.resumen.guia_niveles" | t }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <h2 class="fake_h3_sub_titl">{{ "usuario.logros.ultimos.titulo" | t }}</h2>
                    <div class="ultimos-logros-container">
                        <logro
                            *ngFor="let logroUsuarioPlataforma of ultimosLogros"
                            [logro]="logroUsuarioPlataforma.logro"
                            [completado]="true"
                            [fechaCompletado]="logroUsuarioPlataforma.created_at"
                            [versionMini]="true"
                        ></logro>
                    </div>
                </div>
            </div>
            <br />
            <div>
                <h4>Puntos {{ year }}:</h4>
                <div style="font-size: 24px; color: #3b94cd; margin-left: 40px">
                    {{ experiencia_year }}
                </div>
            </div>
            <br />
            <div>
                <div class="ultimos-actividades-container">
                    <cui-data-list>
                        <cui-data-list-header>{{ "usuario.actividades.title" | t }}</cui-data-list-header>
                        <cui-data-list-item *ngFor="let actividad of actividades; let i = index">
                            <cui-data-list-item-title>{{ actividad.actividad }}</cui-data-list-item-title>
                            <cui-data-list-item-actions>
                                <span class="fat-blue">{{ actividad.experiencia }} pts</span>
                            </cui-data-list-item-actions>
                        </cui-data-list-item>
                        <cui-data-list-item *ngIf="actividades.length === 0">
                            <cui-data-list-item-title>
                                {{ "usuario.actividades.not_found" | t }}
                            </cui-data-list-item-title>
                        </cui-data-list-item>
                    </cui-data-list>
                </div>
            </div>
            <br />
        </div>
        <div class="col-md-7">
            <div>
                <cui-section-headline>{{ "usuario.logros.lista.titulo" | t }}</cui-section-headline>
                <div class="lista-logros-container">
                    <div *ngFor="let categoria of categoriasWithLogros">
                        <div class="categoria-header">
                            <div>{{ categoria.categoria }}</div>
                            <div>
                                {{ countLogrosCompletadosByCategoria[categoria.id] }}/{{ categoria.logros.length }}
                            </div>
                        </div>
                        <div class="categoria-logros">
                            <logro
                                *ngFor="let logro of categoria.logros"
                                [logro]="logro"
                                [completado]="!!logroUsuarioPlataformasByLogroId[logro.id]?.usuario_plataforma_id"
                                [fechaCompletado]="logroUsuarioPlataformasByLogroId[logro.id]?.created_at"
                                [valorProgreso]="logroUsuarioPlataformasByLogroId[logro.id]?.valor_progreso"
                                [valorCompletado]="logroUsuarioPlataformasByLogroId[logro.id]?.valor_completado"
                            ></logro>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</loading-layout>
