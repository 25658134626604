import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core"
import {
    CarreraSede,
    CarreraSedes,
    PreferenciaCarreraSede,
    PreferenciaCarreraSedes,
    Sede,
    Universidad,
    Universidades
} from "@puntaje/carreras/api-services"
import { AppConfig, SessionService } from "@puntaje/shared/core"
import { CUITableComponent } from "@puntaje/shared/cui"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { ModalSedesImagenesComponent } from "../modal-sedes-imagenes/modal-sedes-imagenes.component"
declare const config: AppConfig

@Component({
    selector: "universidad",
    templateUrl: "./universidad.component.html",
    styleUrls: ["./universidad.component.scss"]
})
export class UniversidadComponent implements OnInit {
    universidad: Universidad
    sedeActual: Sede
    sedes: Sede[]
    carrerasSede: CarreraSede[]
    preferenciaCarreraSedesPorCarreraSedeId = {}

    @Input() universidadId: number
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(ModalSedesImagenesComponent, { static: true })
    modalSedesImagenesComponent: ModalSedesImagenesComponent
    @ViewChild(CUITableComponent) cuiTable: CUITableComponent
    @Output() universidadEvent = new EventEmitter()

    constructor(
        private universidadesService: Universidades,
        private carrerasSedeService: CarreraSedes,
        private preferenciaCarreraSedesService: PreferenciaCarreraSedes,
        private sessionService: SessionService
    ) {}

    ngOnInit() {
        const params = {
            include: "[universidad_campo_extras?:campo_extra?,sedes:[sede_imagenes?]]",
            withLikesUniversidad: 1
        }

        this.loadingLayout.standby()

        this.universidadesService.find(this.universidadId, params).then((universidad: Universidad) => {
            this.universidad = universidad

            this.universidadEvent.emit(universidad)

            this.sedes = universidad.sedes || []
            this.sedeActual = this.sedes[0] || null
            this.loadingLayout.ready()
        })
    }

    seleccionarSede(index: number) {
        this.sedeActual = this.sedes[index]
        this.cuiTable.changePage(1)
    }

    cargarCarrerasSede = (page: number, per: number) => {
        const params = {
            include: "[carrera?,info_adicionales?,sede:universidad]",
            carreraSede: { sedeId: this.sedeActual.id },
            page,
            per
        }

        return Promise.all([
            this.carrerasSedeService.wherePost(params),
            this.carrerasSedeService.wherePostCount(params) as unknown as Promise<number>
        ]).then(([carreraSedes, total]) => {
            this.carrerasSede = carreraSedes

            carreraSedes.forEach(cs => {
                this.preferenciaCarreraSedesPorCarreraSedeId[cs.id] = []
            })

            return this.preferenciaCarreraSedesService.where({preferenciaCarreraSede: {usuarioId: this.sessionService.getUserData().id, plataformaId: config.plataforma.id, carreraSedeId: carreraSedes.map(cs => cs.id)}}).then(preferenciaCarreraSedes => {
                preferenciaCarreraSedes.forEach(pcs => {
                    this.preferenciaCarreraSedesPorCarreraSedeId[pcs.carreraSedeId].push(pcs)
                })
                return total
            })
        })
    }

    seguirCarreraSede(carreraSede) {
        const preferenciaCarreraSede = new PreferenciaCarreraSede()
        preferenciaCarreraSede.carreraSedeId = carreraSede.id

        this.preferenciaCarreraSedesService.save(preferenciaCarreraSede).then(pcs => {
            this.preferenciaCarreraSedesPorCarreraSedeId[pcs.carreraSedeId] = [pcs]
        })
    }

    dejarSeguirCarreraSede(carreraSede) {
        this.preferenciaCarreraSedesPorCarreraSedeId[carreraSede.id].forEach(async pcs => {
            await this.preferenciaCarreraSedesService.remove(pcs.id)
        })

        this.preferenciaCarreraSedesPorCarreraSedeId[carreraSede.id] = []
    }

    openModalSedesImagenes(sedeImg) {
        this.modalSedesImagenesComponent.open(sedeImg)
    }
}
