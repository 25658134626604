<cui-showcasebox>
    <cui-showcasebox-title-icon>
        <ogr-icon name="estadistica-o"></ogr-icon>
    </cui-showcasebox-title-icon>
    <cui-showcasebox-title-text>{{ "progreso.historial.recientes.titulo" | t }}</cui-showcasebox-title-text>
    <cui-showcasebox-content>
        <menu-asignaturas-horizontal
            [tipoEvaluacion]="evaluacionTipo"
            [enableResponsive]="enableResponsive"
            (asignaturaObjChanged)="onAsignaturaChange($event)"
        ></menu-asignaturas-horizontal>

        <loading-layout #loadingLayout>
            <div *ngIf="!loadingLayout.loading">
                <div *ngIf="evaluacionInstancias.length; else sinResultados">
                    <table class="table">
                        <tbody>
                            <tr *ngFor="let evaluacionInstancia of evaluacionInstancias; let i = index">
                                <td>
                                    {{
                                        evaluacionInstancia.evaluacion.evaluacion ||
                                            "Ensayo ID " + evaluacionInstancia.id
                                    }}
                                </td>
                                <td>
                                    {{ evaluacionInstancia.created_at | fechaAtras }}
                                </td>
                                <td>
                                    <cui-button-link [routerLinkValue]="[link, evaluacionInstancia.id]">
                                        <!-- <fa name="search" class="icono left-icon"></fa> -->
                                        {{ "progreso.historial.recientes.ver" | t }}
                                    </cui-button-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="text-right">
                        <a [routerLink]="[urlResultados]" [queryParams]="paramsResultados">
                            {{ "progreso.historial.recientes.ver_todos" | t }}
                        </a>
                    </div>
                </div>
                <ng-template #sinResultados>
                    <p class="sin-resultados">
                        {{ "progreso.historial.recientes.none" | t }}
                    </p>
                </ng-template>
            </div>
        </loading-layout>
    </cui-showcasebox-content>
</cui-showcasebox>
