import { Component, HostBinding, Input, ElementRef, ChangeDetectorRef } from "@angular/core"
import { trigger } from "@angular/animations"
import * as animations from "../../custom-animations"

@Component({
    selector: "[anim-on-scroll-fade-in],anim-on-scroll-fade-in",
    template:
        '<ng-container onScrolledToView (enter)="onEnter()" [padding]="padding" [partial]="partial"><ng-content></ng-content></ng-container>',
    animations: [trigger("animFadeInOnScrolled", animations.defaultFadeInOnScrolled())]
})
export class AnimOnScrollFadeInComponent {
    @HostBinding("@animFadeInOnScrolled") state: string = "pending"
    wasViewed: boolean = false
    @Input() padding: number = 150
    @Input() partial: boolean = true
    @Input() delay: number = 100

    constructor(protected el: ElementRef, protected cdr: ChangeDetectorRef) {}

    onEnter() {
        setTimeout(() => {
            this.state = "fadeIn"
        }, this.delay)
    }
}
