import { Component, HostBinding, Input, OnInit, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import {
    Asignatura,
    Asignaturas,
    TipoInstrumento,
    TipoInstrumentos,
    GeneradorInstrumento,
    AsignaturaPlataforma
} from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Subscription } from "rxjs"
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasByEvaluacionTipo, selectEvaluacionTipoAliases } from "@puntaje/puntaje/store"
import { filter, last, first } from "rxjs/operators"

@Component({
    selector: "instrumentos-mensuales-ranking",
    templateUrl: "instrumentos_mensuales_ranking.component.html",
    styleUrls: ["instrumentos_mensuales_ranking.component.scss"]
})
export class InstrumentosMensualesRankingComponent implements OnInit {
    config: typeof config
    evaluacionTipoAlias: string
    @Input() tipoInstrumento: string
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    asignaturas: Asignatura[]
    tipoInstrumentoId: number
    asignaturaId: number
    currentAsignatura: Asignatura
    months: string[] = []
    month: number = new Date().getMonth()
    years: number[] = []
    year: number = new Date().getFullYear()
    generadorInstrumentos: GeneradorInstrumento[]
    generadorInstrumento: GeneradorInstrumento

    sub: Subscription
    subAlias: Subscription
    asignaturasByEvaluacionTipo$ = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x)
    )

    evaluacionTipoAliases$ = this.store.pipe(
        select(selectEvaluacionTipoAliases),
        filter(x => !!x)
    )

    @HostBinding("class") innerClass = "showcasebox_style_1_ranking"

    constructor(private store: Store<State>, private tipoInstrumentosService: TipoInstrumentos) {
        this.config = config
    }

    ngOnInit() {
        this.subAlias = this.evaluacionTipoAliases$.subscribe(evaluacionTipoAliases => {
            this.evaluacionTipoAlias = evaluacionTipoAliases[this.tipoInstrumento]
        })

        this.setData()
    }

    async setData() {
        let asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.pipe(first()).toPromise()
        this.asignaturas = asignaturasByEvaluacionTipo[this.tipoInstrumento]
        this.changeAsignatura(this.asignaturas[0].id)
        this.setTimeSelect()
        this.tipoInstrumentosService
            .where({ tipo_instrumento: { tipo_instrumento: this.tipoInstrumento } })
            .then((tipos: TipoInstrumento[]) => {
                this.tipoInstrumentoId = tipos[0].id
                this.changeGenerador()
                this.loadingLayout.ready()
            })
    }

    onChangeAsignatura(asignaturaId) {
        this.loadingLayout.standby()
        this.changeAsignatura(asignaturaId)
        this.changeGenerador()
        this.loadingLayout.ready()
    }

    changeAsignatura(asignaturaId) {
        this.asignaturaId = asignaturaId
        this.currentAsignatura = this.asignaturas.find((asignatura: Asignatura) => asignatura.id === this.asignaturaId)
    }

    changeGenerador() {
        if (this.tipoInstrumentoId) {
            this.generadorInstrumentos = this.currentAsignatura.asignatura_plataformas[0].generador_instrumentos.filter(
                gi => gi.visible && gi.tipo_instrumento_id == this.tipoInstrumentoId
            )
            this.generadorInstrumento = this.generadorInstrumentos[0]
        }
    }

    setTimeSelect() {
        this.months = [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
        ]
        this.month = new Date().getMonth()
        let yearGap = 1
        if (this.year > 2017) yearGap = this.year - 2017
        else this.year = 2018
        this.years = Array(yearGap)
            .fill(0)
            .map((x, i) => this.year - i)
    }

    onChangeDate() {}
}
