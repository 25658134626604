<loading-layout #loadingLayout>
    <div *ngIf="universidad">
        <div class="row mv" *ngIf="universidad">
            <div class="col-md-2 col-sm-4 col-xs-12">
                <div class="logo-universidad">
                    <img [src]="universidad.logo" alt="" />
                </div>
            </div>
            <div class="col-md-10 col-sm-8 col-xs-12">
                <div class="col-md-12 col-xs-12 contenedor-titulo">
                    <h4 class="titulo-universidad">
                        {{ universidad.universidad }}
                    </h4>
                    <span class="like-container likes-universidad">
                        <fa class="icono" name="heart-o"></fa>
                        {{ universidad.likesUniversidad | easyplaceholder: 0 }}
                        {{ "universidad.universidad.les_gusta" | t }}
                    </span>
                </div>
                <div class="col-md-12 col-xs-12 descripcion-universidad">
                    <div [innerHTML]="universidad.promo"></div>
                </div>
            </div>
        </div>

        <div class="row mv" *ngIf="universidad">
            <div class="col-md-12">
                <cui-section-headline>
                    {{ "universidad.universidad.presentacion" | t }}
                </cui-section-headline>
                <div class="presentacion-media">
                    <div [innerHTML]="universidad.presentacion | trustedhtml"></div>
                </div>
            </div>
        </div>

        <ng-container *ngFor="let universidadCampoExtra of universidad.universidadcampoextras">
            <cui-section-headline>{{ universidadCampoExtra.campoextra.campoExtra }}</cui-section-headline>
            <div [innerHTML]="universidadCampoExtra.campoextra.contenido | trustedhtml"></div>
            <br />
        </ng-container>

        <div class="row mv">
            <div class="col-md-12">
                <cui-section-headline>{{ "universidad.universidad.sedes" | t }}</cui-section-headline>
                <div class="row">
                    <div class="contenedor-sedes">
                        <div class="sede-slide" *ngFor="let sede of sedes; let i = index">
                            <div class="thumbnail" (click)="seleccionarSede(i)">
                                <img
                                    *ngIf="sede.sedeImagenes.length > 0"
                                    [src]="sede.sedeImagenes[0].url"
                                    [alt]="sede.sede"
                                    class="img-sede"
                                />
                                <img
                                    *ngIf="sede.sedeImagenes.length == 0 && universidad.anuncioLink"
                                    [src]="universidad.anuncioLink"
                                    [alt]="sede.sede"
                                    class="img-sede"
                                />

                                <div
                                    *ngIf="sede.sedeImagenes.length == 0 && !universidad.anuncioLink"
                                    class="img-default-sede"
                                >
                                    <ogr-icon name="establecimiento-o" class="icono"></ogr-icon>
                                </div>
                                <div class="caption">
                                    <h4>{{ sede.sede }}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="sedeActual">
                    <div class="col-md-6 col-xs-12">
                        <div class="">
                            <h4>
                                {{ "universidad.universidad.sede" | t }}
                                <strong>{{ sedeActual.sede }}</strong>
                            </h4>
                            <p>{{ sedeActual.descripcion }}</p>
                        </div>
                        <div class="contenedor-img-sede">
                            <ng-container *ngFor="let sedeImg of sedeActual.sedeImagenes">
                                <img
                                    [src]="sedeImg.url"
                                    alt=""
                                    class="img-sede"
                                    (click)="openModalSedesImagenes(sedeImg)"
                                />
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-md-6 col-xs-12 contenedor-video-sede">
                        <div class="embed-responsive embed-responsive-16by9" *ngIf="sedeActual.urlVideo">
                            <iframe class="embed-responsive-item" [src]="sedeActual.urlVideo | trustedurl"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mv">
            <div class="col-md-12">
                <div class="info-container">
                    <cui-section-headline>
                        {{ "universidad.universidad.carreras" | t }}
                    </cui-section-headline>
                    <cui-table [withPaginator]="true" [paginatorGetList]="cargarCarrerasSede" [verticalOnBreak]="700">
                        <tr cui-table-header-row>
                            <th>{{ "universidad.universidad.carrera" | t }}</th>
                            <th>{{ "universidad.universidad.universidad" | t }}</th>
                            <th>{{ "universidad.universidad.sede" | t }}</th>
                            <th class="actions last"></th>
                        </tr>
                        <tr cui-table-data-row *ngFor="let c of carrerasSede">
                            <td class="carrera">
                                <a [routerLink]="['/carrera_sedes', c.id]">{{ c.alias }}</a>
                            </td>
                            <td class="universidad">{{ c.sede.universidad.universidad }}</td>
                            <td class="sede">{{ c.sede.sede }}</td>
                            <td class="actions last">
                                <cui-button-link size="xsmall" [routerLinkValue]="['/carrera_sedes', c.id]">
                                    <fa name="eye" class="icono left-icon"></fa>
                                    {{ "universidad.universidad.ver" | t }}
                                </cui-button-link>
                                <cui-button
                                    size="xsmall"
                                    *ngIf="preferenciaCarreraSedesPorCarreraSedeId[c.id].length === 0"
                                    (click)="seguirCarreraSede(c)"
                                >
                                    <fa name="heart-o" class="icono left-icon"></fa>
                                    {{ "universidad.universidad.seguir" | t }}
                                </cui-button>
                                <span
                                    *ngIf="preferenciaCarreraSedesPorCarreraSedeId[c.id].length > 0"
                                    class="siguiendo"
                                    (click)="dejarSeguirCarreraSede(c)"
                                >
                                    <fa name="heart-o" class="icono"></fa>
                                    {{ "universidad.universidad.siguiendo" | t }}
                                </span>
                            </td>
                        </tr>
                        <cui-table-no-data>
                            {{ "universidad.universidad.carreras_not_found" | t }}
                        </cui-table-no-data>
                    </cui-table>
                </div>
            </div>
        </div>
    </div>

    <modal-sedes-imagenes></modal-sedes-imagenes>
</loading-layout>
