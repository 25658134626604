<loading-layout #loadingLayout>
    <ng-container *ngIf="!loadingLayout.loading">
        <div class="posts-container" *ngIf="posts && posts.length > 0">
            <div class="promoted">
                <post-thumbnail class="post" [external]="true" [post]="posts[0]"></post-thumbnail>
            </div>
            <span class="separador-v"></span>
            <div class="anteriores"><a [routerLink]="['/public_blog']">Entradas anteriores</a></div>
            <div class="others">
                <div class="list">
                    <ng-container *ngFor="let post of posts; let i = index">
                        <post-thumbnail
                            *ngIf="i != 0"
                            [post]="post"
                            class="post item{{ i }}"
                            [class.upper]="i < 3 && posts.length > 2"
                            [external]="true"
                        ></post-thumbnail>
                        <span class="separador-v"></span>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
</loading-layout>
