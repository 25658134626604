import { Component, Input, OnInit } from "@angular/core"
import { SessionService } from "@puntaje/shared/core"
import { DudaRespuestas, EvaluacionInstancias, Respuestas, Usuario } from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"

@Component({
    selector: "resumen-semana-showcasebox",
    templateUrl: "./resumen-semana-showcasebox.component.html",
    styleUrls: ["./resumen-semana-showcasebox.component.scss"]
})
export class ResumenSemanaShowcaseboxComponent implements OnInit {
    avatar: string
    nombre: string
    usuarioAvatar: string

    ensayos: number
    dudasContestadas: number
    preguntasContestadas: number
    @Input() tipoEvaluacion: string
    @Input() linkEvaluacion: any = ["/pruebas_de_transicion", "generar"]

    constructor(
        protected config: AppConfig,
        protected sessionService: SessionService,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected dudaRespuestasService: DudaRespuestas,
        protected respuestasService: Respuestas
    ) {}

    ngOnInit() {
        const usuario = this.sessionService.getUserData()
        this.tipoEvaluacion = this.tipoEvaluacion || this.config.plataforma.evaluacionDefault
        this.avatar = usuario.avatar
        this.nombre = Object.assign(new Usuario(), usuario).nombreCompleto()

        const evaluacionInstanciaParams = {
            evaluacion_tipo: {
                evaluacion_tipo: this.tipoEvaluacion
            },
            evaluacion_instancia: {
                usuario_id: usuario.id
            },
            semana: 1,
            page: 1,
            per: 1
        }

        this.evaluacionInstanciasService.where(evaluacionInstanciaParams).then((evaluacionInstanciasCount, count) => {
            this.ensayos = count
        })

        const dudaRespuestaParams = {
            duda_respuesta: {
                usuario_id: usuario.id
            },
            semana: 1,
            page: 1,
            per: 1
        }

        this.dudaRespuestasService.where(dudaRespuestaParams).then((_, count) => {
            this.dudasContestadas = count
        })

        const respuestasParams = {
            evaluacion_instancia: {
                usuario_id: usuario.id
            },
            respuesta: {
                not: {
                    alternativa_id: [0, "null"]
                }
            },
            semana: 1,
            grouped_by_evaluacion_pregunta: 1,
            page: 1,
            per: 1
        }

        this.respuestasService.where(respuestasParams).then((_, count) => {
            this.preguntasContestadas = count
        })
    }

    toString(word) {
        return word ? word : ""
    }
}
