<cui-section-headline>{{ "universidad.buscador_universidades.titulo" | t }}</cui-section-headline>
<p *ngIf="universidades && universidades.length == 1">{{ universidades[0].universidad }}</p>

<div class="options">
    <div class="buscador-options">
        <div class="tipo-busqueda">
            <label [attr.for]="'radio_1'">
                <span>{{ "universidad.buscador_universidades.universidad" | t }}</span>
            </label>
            <input
                id="input-universidad"
                [(ngModel)]="universidad"
                [typeahead]="filteredUniversidades"
                [typeaheadMinLength]="0"
                placeholder="{{ 'universidad.buscador_universidades.buscador_placeholder' | t }}"
                class="form-control"
                [typeaheadScrollable]="true"
                [typeaheadOptionsInScrollableView]="10"
                (keydown.enter)="updateSearch()"
            />
        </div>

        <div class="search-btn">
            <cui-button (click)="updateSearch()">
                <fa name="search" class="icono left-icon"></fa>
                {{ "universidad.buscador_universidades.buscar" | t }}
            </cui-button>
        </div>
    </div>
</div>

<universidades-listado [universidades]="universidades"></universidades-listado>

<paginator *ngIf="!firstLoad" [getList]="setData"></paginator>
