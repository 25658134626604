<loading-layout #loadingLayout>
    <ng-container *ngIf="!loadingLayout.loading">
        <div *ngIf="planPersonal">
            <cui-section-headline *ngIf="!hideTitle">Progreso de tu plan personal</cui-section-headline>
            <div class="{{ asignatura?.clase }} info-container clearfix print-page-break-after">
                <div class="info-container-icon">
                    <ogr-icon class="icono" [name]="'lenguaje-3'"></ogr-icon>
                </div>
                <div class="info-container-info">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 print-hide">
                            <h4 class="title">{{ planPersonal.plan_personal }}</h4>
                            <p class="asignatura">{{ planPersonal.generador_instrumento.asignatura.asignatura }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <plan-personal-sesiones [planPersonal]="planPersonal" [showDiagnosticoBox]="false"></plan-personal-sesiones>
        </div>
        <!-- <div class="no-plan-yet" *ngIf="!planPersonal">
            <p class="iconos">
            <ogr-icon name="resultados"></ogr-icon>
            <ogr-icon name="libro2-o"></ogr-icon>
            <ogr-icon name="prueba"></ogr-icon>
            </p>
            <button type="button" class="start-btn" (click)="comenzarPlanPersonal()">Comenzar plan personal</button>
        </div> -->
    </ng-container>
</loading-layout>
