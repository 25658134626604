<cui-showcasebox type="notificaciones">
    <cui-showcasebox-title-icon>
        <ogr-icon name="comunidad-o"></ogr-icon>
    </cui-showcasebox-title-icon>
    <cui-showcasebox-title-text>{{ "home.showcasebox.grupo_usuarios.titulo" | t }}</cui-showcasebox-title-text>
    <cui-showcasebox-content>
        <loading-layout [loading]="!grupoUsuariosByEstablecimientoId">
            <div class="container">
                <div
                    class="establecimiento-container"
                    *ngFor="let establecimientoId of grupoUsuariosByEstablecimientoId | keys"
                >
                    <div class="grupo-usuarios">
                        <b>{{ grupoUsuariosByEstablecimientoId[establecimientoId].nombres }}</b>
                    </div>
                    <div class="establecimiento">
                        {{ grupoUsuariosByEstablecimientoId[establecimientoId].establecimiento.establecimiento }}
                    </div>
                </div>

                <div *ngIf="extraActions && extraActions.length > 0" class="extra-actions-container">
                    <div class="extra-action-container" *ngFor="let extraAction of extraActions">
                        <div class="extra-action-name">{{ extraAction.name }}</div>
                        <div class="extra-action-action">
                            <cui-button-link
                                [routerLinkValue]="extraAction.route"
                                [queryParamsValue]="extraAction.params"
                            >
                                Ver
                            </cui-button-link>
                        </div>
                    </div>
                </div>

                <cui-section-headline class="notificaciones-title">
                    {{ "home.showcasebox.grupo_usuarios.actualizaciones" | t }}
                </cui-section-headline>
                <notificaciones
                    [pendientes]="pendientes"
                    [maximoNotificaciones]="maximoNotificaciones"
                    [enableMini]="true"
                    [enableMaximo]="true"
                    [maximoNotificaciones]="maximoNotificaciones"
                ></notificaciones>

                <div class="link-container">
                    <a routerLink="/notificaciones">{{ "home.showcasebox.grupo_usuarios.todos" | t }}</a>
                </div>
            </div>
        </loading-layout>
    </cui-showcasebox-content>
</cui-showcasebox>
