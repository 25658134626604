import { Component, Input, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AuthService } from "@puntaje/shared/core"
import { Universidad, Universidades } from "@puntaje/carreras/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "universidad-resumen",
    templateUrl: "universidad-resumen.component.html",
    styleUrls: ["universidad-resumen.component.scss"]
})
export class UniversidadResumenComponent {
    @Input() universidadId: number
    @Input() universidad: Universidad

    @ViewChild(LoadingLayoutComponent, { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(protected authService: AuthService, protected universidadesService: Universidades) {}

    ngOnInit() {
        const universidadId = this.universidad?.id || this.universidadId

        if (!this.universidad || !this.universidad.sedes) {
            const params = { include: "[sedes]" }
            this.universidadesService.find(universidadId, params).then(universidad => {
                this.universidad = universidad
            })
        }
    }
}
