<div *ngIf="universidadSeleccionada">
    <div class="titulo">
        <h3>{{ "universidad.universidad_recomendada.titulo" | t }}</h3>
    </div>
    <div class="uni-container">
        <div class="logo-container">
            <div *ngIf="universidadSeleccionada.logo">
                <a [routerLink]="['/universidad', universidadSeleccionada.id]">
                    <img alt="{{ universidadSeleccionada.universidad }} - logo" [src]="universidadSeleccionada.logo" />
                </a>
            </div>
            <div *ngIf="!universidadSeleccionada.logo">
                <a [routerLink]="['/universidad', universidadSeleccionada.id]">
                    <img alt="{{ universidadSeleccionada.universidad }} - logo" [src]="noImagen" />
                </a>
            </div>
        </div>
        <p>{{ universidadSeleccionada.universidad }}</p>
    </div>
</div>
