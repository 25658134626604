<div class="justify-content-start">
    <loading-layout>
        <div class="row">
            <div class="col-md-8">
                <div class="uni-container">
                    <ng-container *ngFor="let universidadId of universidadIds">
                        <universidad-resumen [universidadId]="universidadId"></universidad-resumen>
                    </ng-container>
                </div>
            </div>
            <div class="col-md-4">
                <ng-container>
                    <universidad-recomendada></universidad-recomendada>
                </ng-container>
            </div>
        </div>
    </loading-layout>
</div>
