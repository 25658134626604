import { Component, Input, OnInit } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"


@Component({
    selector: "grafico-mi-progreso-showcasebox",
    templateUrl: "./grafico-mi-progreso-showcasebox.component.html",
    styleUrls: ["./grafico-mi-progreso-showcasebox.component.scss"]
})
export class GraficoMiProgresoShowcaseboxComponent implements OnInit {
    @Input() evaluacionTipo = this.config.plataforma.evaluacionDefault
    fechaInicial: Date
    fechaFinal: Date

    constructor(
        protected config: AppConfig,
    ) {}

    ngOnInit() {
        this.fechaFinal = new Date()
        this.fechaInicial = new Date()
        this.fechaInicial.setMonth(this.fechaInicial.getMonth() - 6)
    }
}
