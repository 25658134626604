import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Post, Posts } from "@puntaje/puntaje/api-services"

@Component({
    selector: "blog-landing",
    templateUrl: "./blog-landing.component.html",
    styleUrls: ["./blog-landing.component.scss"]
})
export class BlogLandingComponent implements OnInit {
    posts: Post[]
    @Input() postPerfiles = ["todos"]
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(protected postsServices: Posts) {}

    ngOnInit() {
        this.getData()
    }

    getData() {
        this.loadingLayout.standby()
        const params = {
            page: 1,
            per: 5,
            sort_by: "created_at",
            order: "desc",
            include: "[usuario]",
            post: {
                no_listado: false
            }
        }

        if (this.postPerfiles.length > 0) {
            params["post_perfil"] = { post_perfil: this.postPerfiles }
        }

        this.postsServices.where(params).then((posts: Post[], total: number) => {
            this.posts = posts
            this.loadingLayout.ready()
            return total
        })
    }
}
