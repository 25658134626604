import { Component, EventEmitter, Input, OnInit, ViewChild } from "@angular/core"
import { GenericModalComponent } from "@puntaje/shared/core"

@Component({
    selector: "modal-sedes-imagenes",
    templateUrl: "./modal-sedes-imagenes.component.html",
    styleUrls: ["./modal-sedes-imagenes.component.scss"]
})
export class ModalSedesImagenesComponent implements OnInit {
    sedeImg: any = {}
    openModal: EventEmitter<any> = new EventEmitter<any>()
    @ViewChild(GenericModalComponent, { static: true }) genericModal: GenericModalComponent

    constructor() {}

    ngOnInit() {}

    public open(sedeImg) {
        this.sedeImg = sedeImg
        this.genericModal.buttonPressed()
    }

    public close() {
        this.genericModal.close()
    }
}
