import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "sub-menu",
    templateUrl: "./sub-menu.component.html",
    styleUrls: ["./sub-menu.component.scss"]
})
export class SubMenuComponent implements OnInit {
    @Input() itemsMenu = [] /**  = [{name: '', params: {  }, route: "/hhh"}] */
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor() {}

    ngOnInit() {
        this.loadingLayout.ready()
    }
}
