import { Component } from "@angular/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "buscador-universidades.component.html",
    styleUrls: ["buscador-universidades.component.scss"]
})
export class BuscadorUniversidadesComponent {
    constructor(protected titleService: TitleService) {}
}
