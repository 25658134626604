import { Component, Input, OnInit } from "@angular/core"
import { SessionService } from "@puntaje/shared/core"
import { EvaluacionInstancias, Respuestas } from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"

@Component({
    selector: "resumen-semanal-practicar-showcasebox",
    templateUrl: "./resumen-semanal-practicar-showcasebox.component.html",
    styleUrls: ["./resumen-semanal-practicar-showcasebox.component.scss"]
})
export class ResumenSemanalPracticarShowcaseboxComponent implements OnInit {
    ensayos: number
    preguntasContestadas: number
    preguntasCorrectas: number
    @Input() tipoEvaluacion: string
    @Input() linkEvaluacion: any = ["/pruebas_de_transicion", "generar"]

    constructor(
        protected config: AppConfig,
        protected sessionService: SessionService,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected respuestasService: Respuestas
    ) {}

    ngOnInit() {
        const usuario = this.sessionService.getUserData()
        this.tipoEvaluacion = this.tipoEvaluacion || this.config.plataforma.evaluacionDefault

        const evaluacionInstanciaParams = {
            evaluacion_tipo: {
                evaluacion_tipo: this.tipoEvaluacion
            },
            evaluacion_instancia: {
                usuario_id: usuario.id
            },
            semana: 1,
            page: 1,
            per: 1
        }

        this.evaluacionInstanciasService.where(evaluacionInstanciaParams).then((_, count) => {
            this.ensayos = count
        })

        const respuestasParams = {
            evaluacion_instancia: {
                usuario_id: usuario.id
            },
            respuesta: {
                not: {
                    alternativa_id: [0, "null"]
                }
            },
            semana: 1,
            grouped_by_evaluacion_pregunta: 1,
            page: 1,
            per: 1
        }

        this.respuestasService.where(respuestasParams).then((_, count) => {
            this.preguntasContestadas = count
        })

        const respuestasCorrectasParams = {
            evaluacion_instancia: {
                usuario_id: usuario.id
            },
            respuesta: {
                not: {
                    alternativa_id: [0, "null"]
                }
            },
            semana: 1,
            grouped_by_evaluacion_pregunta: 1,
            correctas: 1,
            page: 1,
            per: 1
        }

        this.respuestasService.where(respuestasCorrectasParams).then((_, count) => {
            this.preguntasCorrectas = count
        })
    }
}
