import { BaseModel, SaveType } from "@puntaje/shared/core"
import { UsuarioPlataforma } from "./usuario_plataformas.model"

export class UsuarioPlataformaExperiencia extends BaseModel {
    usuario_plataforma_id: number
    experiencia: number
    year: number

    @SaveType(() => UsuarioPlataforma) usuario_plataforma: UsuarioPlataforma
}
