import { Component, Input, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "instructions-data-deletion",
    templateUrl: "./instructions-data-deletion.component.html",
    styleUrls: ["./instructions-data-deletion.component.scss"]
})
export class InstructionsDataDeletionComponent implements OnInit {
    companySite: string
    companyName: string
    @Input() mailContacto: string = "contacto@ogr.cl"

    constructor(protected router: Router, protected route: ActivatedRoute) {
        this.companyName = config.plataforma.info.companyName
        this.companySite = config.plataforma.info.companySite
    }
    ngOnInit() {}
}
