<div class="logro-container">
    <div class="logro-logo-contenido">
        <div class="logro-logo" [class.logro-logo-mini]="versionMini">
            <img *ngIf="logro.logo && completado" [src]="logro.logo" />
            <img *ngIf="logro.logo && !completado" [src]="logro.logo" class="gray" />
            <img
                *ngIf="!logro.logo && completado"
                src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/logros/trofeo_g-01.png"
            />
            <img
                *ngIf="!logro.logo && !completado"
                src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/logros/trofeo_g_pending.png"
            />
        </div>
        <div class="logro-contenido">
            <div *ngIf="!logro?.url" class="logro-titulo" [class.logro-titulo-mini]="versionMini">{{ logro.logro }}</div>
            <div *ngIf="logro?.url" class="logro-titulo text-bold" [class.logro-titulo-mini]="versionMini">
                <a [routerLink]="['/' + logro.url]" >{{ logro.logro }}</a>
            </div>
            <div *ngIf="!versionMini" class="logro-descripcion">{{ logro.descripcion }}</div>
            <div class="logro-contenido-aligned">
                <div *ngIf="valorCompletado" class="logro-completado">{{ valorProgreso }}/{{ valorCompletado }}</div>
                <div 
                    *ngIf="!versionMini" 
                    [class.gray]="!completado" 
                    class="logro-experiencia" 
                    tooltip="{{ 'usuario.logros.logro.experiencia' | t }}"
                >
                    <fa name="plus" class="plus-icon"></fa>
                    <span class="puntos-experiencia">{{ logro.experiencia }}</span>
                </div>
            </div>
            <div *ngIf="fechaCompletado" class="logro-fecha">
                <fa name="clock-o" class="warning-icon"></fa>
                <span>{{ fechaCompletado | fechaAtras | capitalize }}</span>
            </div>
        </div>
    </div>
</div>
