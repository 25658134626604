import { Component, Input, OnInit } from "@angular/core"
import { AuthService } from "@puntaje/shared/core"
import { Noticia, NoticiaInstancia, NoticiaInstancias, Noticias } from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "noticias-aviso-home",
    templateUrl: "./noticias-aviso-home.component.html",
    styleUrls: ["./noticias-aviso-home.component.scss"]
})
export class NoticiasAvisoHomeComponent implements OnInit {
    @Input("perfiles") perfiles: string

    noticias: Noticia[]
    noticiaPerfiles: string[] = ["todos"]
    config: typeof config = config

    constructor(
        protected noticiasService: Noticias,
        protected noticiaInstanciaService: NoticiaInstancias,
        protected authService: AuthService
    ) {}

    ngOnInit() {
        let today = new Date().toISOString()

        if (this.perfiles) {
            this.noticiaPerfiles = this.perfiles.split(",")
        }

        this.noticiasService
            .where({
                noticia: {
                    lte: {
                        fecha_inicio: [today, "null"]
                    },
                    gte: {
                        fecha_termino: [today, "null"]
                    }
                },
                noticia_tipo: { noticia_tipo: "aviso" },
                noticias_perfil: { noticia_perfil: this.noticiaPerfiles },
                no_vistas: true
            })
            .then(noticias => (this.noticias = noticias))
    }

    marcarVisto(noticia: Noticia) {
        let ni = new NoticiaInstancia()
        ni.usuario_id = this.authService.getUserData().id
        ni.noticia_id = noticia.id
        this.noticiaInstanciaService.save(ni)
    }
}
