import { Component, OnInit } from "@angular/core"
import { UniversidadRecomendadas, UniversidadRecomendada } from "@puntaje/carreras/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "universidad-recomendada",
    templateUrl: "./universidad-recomendada.component.html",
    styleUrls: ["./universidad-recomendada.component.scss"]
})
export class UniversidadRecomendadaComponent implements OnInit {
    universidadesRecomendadas: UniversidadRecomendada[]
    nombrePlataforma: any
    noImagen: any
    universidadSeleccionada: any

    constructor(protected universidadRecomendadasService: UniversidadRecomendadas) {
        this.nombrePlataforma = config.plataforma.name
        this.noImagen = config.app.assets.logoSquare
    }

    ngOnInit() {
        this.getUniversidades()
    }

    getUniversidades() {
        this.universidadRecomendadasService.where({ include: "[plataforma,universidad]" }).then(todasUnisRecos => {
            this.universidadesRecomendadas = todasUnisRecos.filter(
                tur => tur.plataforma.plataforma == this.nombrePlataforma
            )
            if (this.universidadesRecomendadas.length > 0)
                this.universidadSeleccionada = this.universidadesRecomendadas[0].universidad
        })
    }
}
