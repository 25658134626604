<div class="showcasebox">
    <div class="showcasebox_title">
        <div class="showcasebox_title_inner">
            <div class="showcasebox_title_icon">
                <div class="icon-container"><ogr-icon name="trofeo" class="icon"></ogr-icon></div>
            </div>
            <div class="showcasebox_title_text">
                <!-- Ranking {{ tipoInstrumento | transformInstrumento: "singular" | capitalize }} mensual -->
                {{
                    "practicar.showcasebox.ranking_evaluaciones_mensuales.titulo"
                        | t: { evaluacionTipo: (evaluacionTipoAlias | capitalize) }
                }}
            </div>
        </div>
    </div>
    <div class="showcasebox_content">
        <loading-layout #loadingLayout>
            <div class="time-select clearfix">
                <select class="form-control select-m" [(ngModel)]="month" (ngModelChange)="onChangeDate()">
                    <option *ngFor="let m of months; let i = index" [value]="i">
                        <!-- {{ m }} -->
                        {{ m | te }}
                    </option>
                </select>
                <select class="form-control select-y" [(ngModel)]="year" [disabled]="years.length == 1">
                    <option *ngFor="let y of years; let i = index" [value]="y">{{ y }}</option>
                </select>
            </div>
            <menu-asignaturas-horizontal
                [asignaturaId]="asignaturaId"
                [tipoAsignaturas]="tipoInstrumento"
                [enableResponsive]="true"
                [enableMiniMode]="true"
                (asignaturaChanged)="onChangeAsignatura($event)"
            ></menu-asignaturas-horizontal>
            <ng-container *ngIf="generadorInstrumentos && generadorInstrumentos.length > 1">
                <generador-instrumento-select
                    [generadorInstrumentos]="generadorInstrumentos"
                    [(generadorInstrumento)]="generadorInstrumento"
                    class="select-generador"
                ></generador-instrumento-select>
            </ng-container>
            <instrumento-mensual-ranking
                *ngIf="tipoInstrumentoId"
                [asignatura]="currentAsignatura"
                [tipoInstrumentoId]="tipoInstrumentoId"
                [month]="month"
                [year]="year"
                [generadorInstrumento]="generadorInstrumento"
            ></instrumento-mensual-ranking>
        </loading-layout>
    </div>
</div>
