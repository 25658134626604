<cui-showcasebox type="notificaciones">
    <cui-showcasebox-title-icon>
        <ogr-icon name="guia-2"></ogr-icon>
    </cui-showcasebox-title-icon>
    <cui-showcasebox-title-text>{{ "orientacion.blog_showcasebox.titulo" | t }}</cui-showcasebox-title-text>
    <cui-showcasebox-content>
        <loading-layout #loadingLayout>
            <ng-container *ngIf="post">
                <figure class="figure">
                    <a [routerLink]="['/posts', post.slug]">
                        <img [src]="post.imagen" class="" alt="{{ post.titulo }}" />
                    </a>
                </figure>
                <p class="tag text-muted">
                    <b>
                        <small>{{ post.tag }}</small>
                    </b>
                </p>
                <p class="titulo">
                    <a [routerLink]="['/posts', post.slug]">{{ post.titulo }}</a>
                </p>
                <p class="resumen">
                    {{ post.resumen }}
                </p>
                <p class="text-muted">
                    <span>{{ post.fecha_inicial | date: "dd-MM-yyyy" }}</span>
                    <span class="separador">&sdot;</span>
                    <span>{{ post.tiempo_lectura }} min</span>
                </p>
            </ng-container>
            <p class="not-found" *ngIf="!post">{{ "orientacion.blog_showcasebox.not_found" | t }}</p>
        </loading-layout>
    </cui-showcasebox-content>
</cui-showcasebox>
