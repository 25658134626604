import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import {
    PlanPersonal,
    PlanPersonales,
    PlanPersonalInstancia,
    PlanPersonalInstancias
} from "@puntaje/puntaje/api-services"
import { Subscription, BehaviorSubject, combineLatest } from "rxjs"
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasByEvaluacionTipo } from "@puntaje/puntaje/store"
import { filter } from "rxjs/operators"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "plan-personal-alumno",
    templateUrl: "./plan-personal-alumno.component.html",
    styleUrls: ["./plan-personal-alumno.component.scss"]
})
export class PlanPersonalAlumnoComponent implements OnInit {
    @Input() hideTitle: boolean
    @Input() planPersonalId: number

    planPersonal: PlanPersonal
    planPersonalInstancia: PlanPersonalInstancia
    asignatura: any
    usuarioId: number

    sub: Subscription
    planPersonalSubject = new BehaviorSubject<PlanPersonal>(null)
    asignaturasByEvaluacionTipo$ = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x)
    )

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        private store: Store<State>,
        protected planPersonalesService: PlanPersonales,
        protected planPersonalInstanciasService: PlanPersonalInstancias,
        protected authService: AuthService
    ) {}

    ngOnInit() {
        this.sub = combineLatest(
            this.planPersonalSubject.pipe(filter(x => !!x)),
            this.asignaturasByEvaluacionTipo$
        ).subscribe(([planificacion, asignaturasByEvaluacionTipo]) => {
            let lista_asignaturas = asignaturasByEvaluacionTipo[config.plataforma.evaluacionDefault]
            this.asignatura = lista_asignaturas.find(
                l => l.asignatura == this.planPersonal.generador_instrumento.asignatura.asignatura
            )
        })

        this.usuarioId = this.authService.getUserData().id

        this.planPersonalInstanciasService
            .where({ plan_personal_instancia: { plan_personal_id: this.planPersonalId, usuario_id: this.usuarioId } })
            .then((planPersonalInstancias: PlanPersonalInstancia[]) => {
                if (planPersonalInstancias.length > 0) {
                    this.planPersonalInstancia = planPersonalInstancias[0]
                    this.setPlanPersonalWithInstancia()
                } else {
                    let planPersonalInstancia = new PlanPersonalInstancia()
                    planPersonalInstancia.plan_personal_id = this.planPersonalId
                    planPersonalInstancia.usuario_id = this.usuarioId

                    this.planPersonalInstanciasService
                        .save(planPersonalInstancia)
                        .then((planPersonalInstancia: PlanPersonalInstancia) => {
                            this.planPersonalInstancia = planPersonalInstancia
                            this.setPlanPersonalWithInstancia()
                        })
                }
            })
    }

    setPlanPersonalWithInstancia() {
        let include_str =
            "[" +
            "plan_personal_tipo," +
            "generador_instrumento:[asignatura]," +
            "plan_personal_ciclos:[" +
            "plan_personal_sesiones:[" +
            "plan_personal_contenidos:[" +
            "plan_personal_material_instancias," +
            "plan_personal_evaluacion_instancias:[evaluacion_instancia]]]]]"

        let ppParams = {
            plan_personal: {
                id: this.planPersonalId
            },
            plan_personal_material_instancia: {
                plan_personal_instancia_id: [this.planPersonalInstancia.id, null]
            },
            plan_personal_evaluacion_instancia: {
                plan_personal_instancia_id: [this.planPersonalInstancia.id, null]
            },
            include: include_str
        }

        this.planPersonalesService.find(this.planPersonalId, ppParams).then(planPersonal => {
            this.planPersonal = planPersonal
            this.planPersonalSubject.next(this.planPersonal)

            this.loadingLayout.ready()
        })
    }
}
