<ng-content></ng-content>
<a class="invisible-toggle" (click)="open()"></a>
<div #menu>
    <div @menu *ngIf="openContainer" class="notificaciones">
        <div class="top-container">
            <p>{{ "notificaciones.titulo" | t }}</p>
            <button type="button" class="pull-right ready-btn" (click)="close()">
                <ogr-icon [name]="'cruz'"></ogr-icon>
            </button>
        </div>
        <notificaciones
            [pendientes]="pendientes"
            [maximoNotificaciones]="maximoNotificaciones"
            [enableMini]="true"
            [enableMaximo]="true"
            [maximoNotificaciones]="maximoNotificaciones"
        ></notificaciones>
        <a class="view-more" routerLink="/notificaciones">{{ "notificaciones.ver_todas" | t }}</a>
    </div>
</div>
