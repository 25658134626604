<cui-showcasebox type="logros">
    <cui-showcasebox-title-icon>
        <ogr-icon name="trofeo-o"></ogr-icon>
    </cui-showcasebox-title-icon>
    <cui-showcasebox-title-text>
        {{ "practicar.showcasebox.ranking_comunidad.titulo" | t }} {{ this.year }}
    </cui-showcasebox-title-text>
    <cui-showcasebox-content>
        <loading-layout #loadingLayout></loading-layout>
        <div [class.hide-until-load]="loadingLayout.loading" *ngIf="nivelesReady">
            <table class="table table-condensed table-striped tabla-ranking" *ngIf="ranking && ranking.length > 0">
                <thead>
                    <tr>
                        <th colspan="2">{{ "general.nombre" | t }}</th>
                        <th>{{ "practicar.showcasebox.ranking_comunidad.nivel" | t }}</th>
                        <th class="calificacion">{{ "practicar.showcasebox.ranking_comunidad.puntos" | t }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let r of ranking; let i = index">
                        <td class="posicion">
                            <span *ngIf="i == 0 && currentPage == 1">
                                <ogr-icon name="trofeo" class="trofeo"></ogr-icon>
                            </span>
                            <span *ngIf="!(i == 0 && currentPage == 1)">{{ (currentPage - 1) * perPage + i + 1 }}</span>
                        </td>
                        <td>
                            <ng-container *ngIf="usuariosRanking">
                                {{
                                    usuariosRanking[r.usuario_plataforma.usuario_id]?.nombre_completo
                                        | easyplaceholder: "-"
                                }}
                            </ng-container>
                        </td>
                        <td>{{ nivelesRanking[i].nivel }}</td>
                        <td class="calificacion">{{ r.experiencia }}</td>
                    </tr>
                </tbody>
            </table>

            <div class="clearfix">
                <paginator
                    [getList]="showAll"
                    [per]="perPage"
                    [maxPage]="maxPage"
                    [extraMiniMode]="true"
                    class="paginator"
                ></paginator>
            </div>

            <!-- <h3 class="pos-title">
                <fa name="user-o"></fa>
                {{ "practicar.showcasebox.ranking_evaluaciones_mensuales.posicion" | t }}
            </h3> -->

            <table class="table table-condensed table-striped tabla-ranking user-pos" *ngIf="nivelUsuario">
                <tbody>
                    <tr>
                        <td class="posicion bold-text">{{ usuarioPosRanking + 1 }}</td>
                        <td class="bold-text">
                            {{ nombreCompleto }}
                        </td>
                        <td class="bold-text">{{ nivelUsuario.nivel }}</td>
                        <td class="calificacion bold-text">{{ getUsuarioRanking[usuarioPosRanking].experiencia }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </cui-showcasebox-content>
</cui-showcasebox>
