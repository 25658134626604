<cui-showcasebox>
    <cui-showcasebox-title-icon>
        <ogr-icon name="estadistica-o"></ogr-icon>
    </cui-showcasebox-title-icon>
    <cui-showcasebox-title-text>
        {{ "progreso.historial.estadisticas.resumen" | t }}
    </cui-showcasebox-title-text>
    <cui-showcasebox-content>
        <ng-container *ngFor="let asignatura of asignaturas">
            <div class="asignatura-resumen-container">
                <div class="asignatura" [ngClass]="[asignatura.clase]">{{ asignatura.asignaturaPlataforma }}</div>
                <div class="values-wrap">
                    <div class="value-container">
                        <div class="value">
                            <b>{{ dataByAsignaturaId[asignatura.id].ultimo | roundfloat | easyplaceholder: "-" }}</b>
                        </div>
                        <div class="value-header">{{ "progreso.historial.estadisticas.reciente" | t }}</div>
                    </div>
                    <div class="value-container">
                        <div
                            class="value"
                            [ngClass]="{
                                positivo: dataByAsignaturaId[asignatura.id].tendencia > 0,
                                negativo: dataByAsignaturaId[asignatura.id].tendencia < 0
                            }"
                        >
                            <b>
                                {{
                                    dataByAsignaturaId[asignatura.id].tendencia &&
                                    dataByAsignaturaId[asignatura.id].tendencia > 0
                                        ? "+"
                                        : ""
                                }}{{ dataByAsignaturaId[asignatura.id].tendencia | roundfloat | easyplaceholder: "-" }}
                            </b>
                        </div>
                        <div class="value-header">{{ "progreso.historial.estadisticas.tendencia" | t }}</div>
                    </div>
                    <div class="value-container">
                        <div class="value">
                            <b>{{ dataByAsignaturaId[asignatura.id].promedio | roundfloat | easyplaceholder: "-" }}</b>
                        </div>
                        <div class="value-header">{{ "progreso.historial.estadisticas.promedio" | t }}</div>
                    </div>
                    <div class="value-container">
                        <div class="value maximo">
                            <b>{{ dataByAsignaturaId[asignatura.id].maximo | roundfloat | easyplaceholder: "-" }}</b>
                            <ogr-icon
                                class="trofeo"
                                *ngIf="dataByAsignaturaId[asignatura.id].maximo == 1000"
                                name="trofeo"
                            ></ogr-icon>
                        </div>
                        <div class="value-header">{{ "progreso.historial.estadisticas.maximo" | t }}</div>
                    </div>
                </div>
            </div>
        </ng-container>
    </cui-showcasebox-content>
</cui-showcasebox>
