<loading-layout #loadingLayout></loading-layout>
<div [class.hide-until-load]="loadingLayout.loading">
    <div class="ranking-wrap">
        <loading-layout #loadingLayoutInside [opaque]="true">
            <h3 class="top-title">
                <ogr-icon name="laurel-left-o" class="laurel" align="top"></ogr-icon>
                {{ "practicar.showcasebox.ranking_evaluaciones_mensuales.top_50" | t }}
                <ogr-icon name="laurel-right-o" class="laurel" align="top"></ogr-icon>
            </h3>
            <p class="top-subtitle {{ asignatura?.clase }}">{{ asignatura?.asignaturaPlataforma }}</p>
            <table
                class="table table-condensed table-striped tabla-ranking"
                *ngIf="evaluacionesInstancia && evaluacionesInstancia.length > 0"
            >
                <thead>
                    <tr class="{{ asignatura?.clase }}">
                        <th colspan="2">{{ "general.nombre" | t }}</th>
                        <th class="calificacion">
                            {{ "practicar.showcasebox.ranking_evaluaciones_mensuales.calificacion" | t }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let evalInstancia of evaluacionesInstancia; let i = index">
                        <td class="posicion">
                            <span *ngIf="evalInstancia.calificacion == maxScore && i == 0 && currentPage == 1">
                                <ogr-icon name="trofeo" class="trofeo"></ogr-icon>
                            </span>
                            <span *ngIf="!(i == 0 && currentPage == 1)">{{ (currentPage - 1) * perPage + i + 1 }}</span>
                        </td>
                        <td>
                            {{ evalInstancia.usuario_simple }}
                        </td>
                        <td class="calificacion">{{ evalInstancia.calificacion | roundfloat }}</td>
                    </tr>
                </tbody>
            </table>
        </loading-layout>
    </div>
    <div class="clearfix">
        <paginator
            *ngIf="paginatorActive"
            [getList]="showAll"
            [per]="perPage"
            [maxPage]="10"
            [extraMiniMode]="true"
            class="paginator"
        ></paginator>
        <p *ngIf="evaluacionesInstancia && evaluacionesInstancia.length > 0" class="participantes">
            {{ "practicar.showcasebox.ranking_evaluaciones_mensuales.total" | t }}:
            <fa name="user-o"></fa>
            <b>({{ totalParticipacion }})</b>
        </p>
    </div>
    <div *ngIf="!evaluacionesInstancia || evaluacionesInstancia.length === 0">
        <p class="not-found">{{ "practicar.showcasebox.ranking_evaluaciones_mensuales.sin_datos" | t }}</p>
    </div>
    <h3 class="pos-title">
        <fa name="user-o"></fa>
        {{ "practicar.showcasebox.ranking_evaluaciones_mensuales.posicion" | t }}
    </h3>
    <div *ngIf="evaluacionInstanciaRanked">
        <table class="table table-condensed table-striped tabla-ranking user-pos {{ asignatura?.clase }}">
            <tbody>
                <tr>
                    <td class="posicion">{{ evaluacionInstanciaRanked.row }}</td>
                    <td>{{ evaluacionInstanciaRanked.usuario_simple }}</td>
                    <td class="calificacion">{{ evaluacionInstanciaRanked.calificacion | roundfloat }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="!evaluacionInstanciaRanked">
        <p class="not-found">{{ "practicar.showcasebox.ranking_evaluaciones_mensuales.not_found" | t }}</p>
    </div>
</div>
