<div class="component-container">
    <div class="left-container">
        <div class="user-circle-container">
            <img *ngIf="avatar; else other_avatar" alt="avatar" class="avatar" [src]="avatar | trustedurl" />
            <ng-template #other_avatar><fa class="fa-placeholder" [name]="'user'"></fa></ng-template>
        </div>
        <div class="nombre">
            {{ nombre }}
        </div>
    </div>
    <div class="right-container">
        <div class="title">{{ "home.resumen.semana" | t }}</div>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <div class="value-container">
                    <div class="value">
                        <b>{{ ensayos | easyplaceholder: "-" }}</b>
                    </div>
                    <div class="value-footer">
                        <a [routerLink]="linkEvaluacion" [queryParams]="{ tipo_instrumento: tipoEvaluacion }">
                            {{ "home.resumen.ensayos" | t }}
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="value-container">
                    <div class="value">
                        <b>{{ preguntasContestadas | easyplaceholder: "-" }}</b>
                    </div>
                    <div class="value-footer">{{ "home.resumen.preguntas" | t }}</div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="value-container">
                    <div class="value">
                        <b>{{ dudasContestadas | easyplaceholder: "-" }}</b>
                    </div>
                    <div class="value-footer">
                        <a [routerLink]="['/dudas_categorias']">{{ "home.resumen.dudas" | t }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
