import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Post, Posts } from "@puntaje/puntaje/api-services"

@Component({
    selector: "blog-showcasebox",
    templateUrl: "./blog-showcasebox.component.html",
    styleUrls: ["./blog-showcasebox.component.scss"]
})
export class BlogShowcaseboxComponent implements OnInit {
    post: Post
    @Input() postPerfiles = ["todos"]
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(protected postsServices: Posts) {}

    ngOnInit() {
        this.loadingLayout.standby()
        const params = {
            page: 1,
            per: 1,
            sort_by: "created_at",
            order: "desc",
            include: "[usuario]",
            post_perfil: {
                post_perfil: this.postPerfiles
            },
        }

        this.postsServices.where(params).then((post: Post[], total: number) => {
            if (post.length > 0) this.post = post[0]
            this.loadingLayout.ready()
            return total
        })
    }
}
