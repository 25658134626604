import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"

import { UniversidadModule } from "@puntaje/puntaje/new-modules/universidad"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { BuscadorUniversidadesComponent } from "./buscador-universidades/buscador-universidades.component"
import { UniversidadComponent } from "./universidad/universidad.component"
import { UtilModule } from "@puntaje/shared/core"

@NgModule({
    declarations: [BuscadorUniversidadesComponent, UniversidadComponent],
    exports: [BuscadorUniversidadesComponent, UniversidadComponent],
    imports: [CommonModule, UniversidadModule, CUILayoutsModule, UtilModule]
})
export class PuntajeUniversidadModule {}
