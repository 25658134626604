import { Component, ViewChild, ChangeDetectorRef, ElementRef, Input } from "@angular/core"
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser"

@Component({
    selector: "embedded-youtube-video",
    template: `
        <div #iframeContainer (window:resize)="onResize($event)" *ngIf="urlReady">
            <iframe [width]="width" [height]="height" [src]="safeUrl" (load)="onLoadIframe()"></iframe>
        </div>
    `,
    styleUrls: ["./embedded_youtube_video.component.scss"]
})
export class EmbeddedYoutubeVideoComponent {
    width: number = 640
    height: number = 480
    max_width: number = 640
    max_height: number = 480
    @Input() url: string
    safeUrl: SafeResourceUrl
    urlReady: boolean = false
    @ViewChild("iframeContainer") iframeContainer: ElementRef

    constructor(private sanitizer: DomSanitizer, private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        if (!this.url) return

        this.url = this.url.replace("watch?v=", "embed/")
        if (this.url.indexOf("youtu.be/") >= 0) {
            let y_code = this.url.substr(this.url.indexOf("youtu.be/") + 9, this.url.length)
            this.url = "https://youtube.com/embed/" + y_code
        }
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url)
        this.urlReady = true
    }

    onResize(event) {
        this.setRatioSize()
    }

    setRatioSize() {
        if (this.iframeContainer) {
            if (this.iframeContainer.nativeElement.offsetWidth > this.max_width) {
                this.width = this.max_width
            } else {
                this.width = this.iframeContainer.nativeElement.offsetWidth
            }
        }
        this.height = (this.width * this.max_height) / this.max_width
    }

    onLoadIframe() {
        this.cdr.detectChanges()
        this.setRatioSize()
    }
}
