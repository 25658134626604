import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { RouterModule } from "@angular/router"
import {
    CUIInputsModule,
    CUITextsModule,
    CUITablesModule,
    CUIContainersModule,
    CUILayoutsModule
} from "@puntaje/shared/cui"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { TypeaheadModule } from "ngx-bootstrap/typeahead"
import { UtilModule, PaginatorModule, TranslationsModule } from "@puntaje/shared/core"
import { UniversidadComponent } from "./universidad/universidad.component"
import { BuscadorUniversidadesComponent } from "./buscador-universidades/buscador-universidades.component"
import { UniversidadResumenComponent } from "./universidad-resumen/universidad-resumen.component"
import { UniversidadesListadoComponent } from "./universidades-listado/universidades-listado.component"
import { UniversidadRecomendadaComponent } from "./universidad-recomendada/universidad-recomendada.component"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { CarrerasModule } from "@puntaje/puntaje/new-modules/carreras";
import { ModalSedesImagenesComponent } from './modal-sedes-imagenes/modal-sedes-imagenes.component'

@NgModule({
    declarations: [
        UniversidadComponent,
        BuscadorUniversidadesComponent,
        UniversidadResumenComponent,
        UniversidadesListadoComponent,
        UniversidadRecomendadaComponent,
        ModalSedesImagenesComponent
    ],
    imports: [
        CommonModule,
        UtilModule,
        TranslationsModule,
        RouterModule,
        CUIContainersModule,
        CUIInputsModule,
        CUILayoutsModule,
        CUITablesModule,
        CUITextsModule,
        Angular2FontawesomeModule,
        CommonsLayoutsModule,
        FormsModule,
        ReactiveFormsModule,
        TypeaheadModule,
        PaginatorModule,
        CarrerasModule
    ],
    exports: [
        UniversidadComponent,
        BuscadorUniversidadesComponent,
        UniversidadResumenComponent,
        UniversidadesListadoComponent,
        UniversidadRecomendadaComponent
    ]
})
export class UniversidadModule {}
