<cui-showcasebox>
    <cui-showcasebox-title-icon>
        <ogr-icon name="estadistica-o"></ogr-icon>
    </cui-showcasebox-title-icon>
    <cui-showcasebox-title-text>{{ "progreso.showcasebox.titulo" | t }}</cui-showcasebox-title-text>
    <cui-showcasebox-content>
        <grafico-mi-progreso
            [timeFrame]="[fechaInicial, fechaFinal]"
            [innerTimeFrame]="false"
            [withoutProgresoClasificacion]="true"
            [evaluacionTipo]="evaluacionTipo"
        ></grafico-mi-progreso>
    </cui-showcasebox-content>
</cui-showcasebox>
