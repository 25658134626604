import { Component, Input, OnInit } from "@angular/core"
import { select, Store } from "@ngrx/store"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AsignaturaWithConfig } from "@puntaje/nebulosa/api-services"
import { EvaluacionInstancias } from "@puntaje/puntaje/api-services"
import { selectAsignaturasByEvaluacionTipo, State } from "@puntaje/puntaje/store"
import { filter, first } from "rxjs/operators"

@Component({
    selector: "resumen-estadisticas-showcasebox",
    templateUrl: "./resumen-estadisticas-showcasebox.component.html",
    styleUrls: ["./resumen-estadisticas-showcasebox.component.scss"]
})
export class ResumenEstadisticasShowcaseboxComponent implements OnInit {
    @Input() evaluacionTipo: string = config.plataforma.evaluacionDefault

    dataByAsignaturaId: any

    asignaturasByEvaluacionTipo$ = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x),
        first()
    )

    asignaturas: AsignaturaWithConfig[]

    constructor(protected store: Store<State>, protected evaluacionInstanciasService: EvaluacionInstancias) {}

    ngOnInit() {
        this.getData()
    }

    async getData() {
        const asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.toPromise()
        const asignaturas = asignaturasByEvaluacionTipo[this.evaluacionTipo]

        const params = {
            evaluacion_tipo: this.evaluacionTipo,
            asignatura_ids: asignaturas.map(a => a.id),
            evaluacion: {
                sumativa: true,
            },
        }

        this.dataByAsignaturaId = await this.evaluacionInstanciasService.resumen(params)

        this.asignaturas = asignaturas
    }
}
