<cui-showcasebox>
    <cui-showcasebox-title-icon>
        <ogr-icon name="material-favorito-o" class="icono"></ogr-icon>
    </cui-showcasebox-title-icon>
    <cui-showcasebox-title-text>{{ "estudiar.showcasebox.material_favorito.titulo" | t }}</cui-showcasebox-title-text>
    <cui-showcasebox-content>
        <loading-layout #loadingLayout>
            <div class="content">
                <p>{{ "estudiar.showcasebox.material_favorito.blurb" | t }}</p>
                <ul *ngIf="materialFavoritos.length > 0">
                    <li class="content-material-favorito" *ngFor="let materialFavorito of materialFavoritos">
                        <div class="content-name-icono">
                            <tipo-material-icon
                                class="icono"
                                [tipo]="materialFavorito.material.material_tipo.material_tipo"
                            ></tipo-material-icon>
                            <p class="material-name">{{ materialFavorito.material.material }}</p>
                        </div>

                        <cui-button-link [routerLinkValue]="['/materiales', materialFavorito.material.id]">
                            {{ "estudiar.showcasebox.material_favorito.ver" | t }}
                        </cui-button-link>
                    </li>
                </ul>
                <cui-button-link [isBlock]="true" [routerLinkValue]="['/usuarios', usuario.id]">
                    {{ "estudiar.showcasebox.material_favorito.lista" | t }}
                </cui-button-link>
            </div>
        </loading-layout>
    </cui-showcasebox-content>
</cui-showcasebox>
