<cui-showcasebox [openStyle]="true">
    <cui-showcasebox-title-icon>
        <ogr-icon name="libro2-o" align="top"></ogr-icon>
    </cui-showcasebox-title-icon>
    <cui-showcasebox-title-text>{{ "participa.rrss_showcase.titulo" | t }}</cui-showcasebox-title-text>
    <cui-showcasebox-content>
        <div class="socialMedia">
            <div *ngFor="let resSocial of redesSociales">
                <a
                    [class]="resSocial.class"
                    href="{{ config.plataforma.info.social[resSocial.class] }}"
                    target="_blank"
                    [title]="resSocial.title"
                >
                    <fa *ngIf="resSocial.class != 'tiktok'" [name]="resSocial.class"></fa>
                    <svg
                        *ngIf="resSocial.class == 'tiktok'"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="tiktok"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        class="svg-inline--fa fa-tiktok fa-w-14"
                    >
                        <path
                            fill="currentColor"
                            d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"
                            class=""
                        ></path>
                    </svg>
                </a>
            </div>
        </div>
    </cui-showcasebox-content>
</cui-showcasebox>
