import { Component, OnInit, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { MaterialFavorito, MaterialFavoritos, Usuario } from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "material-favorito-showcasebox",
    templateUrl: "./material-favorito-showcasebox.component.html",
    styleUrls: ["./material-favorito-showcasebox.component.scss"]
})
export class MaterialFavoritoShowcaseboxComponent implements OnInit {
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    asignatura: Asignatura
    materialFavoritos: MaterialFavorito[] = []
    usuario: Usuario

    constructor(protected authService: AuthService, protected materialFavoritosService: MaterialFavoritos) {
        this.usuario = this.authService.getUserData()
    }

    ngOnInit() {
        this.getFavoritos()
    }

    getFavoritos() {
        if (this.loadingLayout) this.loadingLayout.standby()
        let params = {
            page: 1,
            per: 3,
            material_favorito: {
                usuario_id: this.usuario.id
            },
            methods: "material"
        }

        return this.materialFavoritosService.where(params).then((response: MaterialFavorito[], total: number) => {
            this.materialFavoritos = response
            if (this.loadingLayout) this.loadingLayout.ready()
        })
    }
}
