<div class="component-container" *ngIf="universidad">
    <div class="left-container">
        <div class="logo-container">
            <img *ngIf="universidad.logo" alt="{{ universidad.universidad }} - logo" [src]="universidad.logo" />
            <ogr-icon *ngIf="!universidad.logo" name="establecimiento-o"></ogr-icon>
        </div>
    </div>
    <div class="right-container">
        <div class="title">
            <a [routerLink]="['/universidad', universidad.id]">{{ universidad.universidad }}</a>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="value-container">
                    <ng-container *ngIf="universidad && universidad.sedes">
                        <ng-container *ngIf="universidad.sedes.length == 1">
                            <b>Sede única en</b>
                            {{ universidad.sedes[0].sede.replace("Sede ", "") }}
                        </ng-container>
                        <ng-container *ngIf="universidad.sedes.length > 1">
                            <b>Sedes</b>
                            :
                            <ng-container *ngFor="let sede of universidad.sedes; let last = last" class="value">
                                {{ sede.sede }}
                                <ng-container *ngIf="!last">,</ng-container>
                            </ng-container>
                            .
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
