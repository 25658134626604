import { Component, OnInit, Input, ViewChild, HostListener } from "@angular/core"
import { Notificacion, Notificaciones } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { NotificacionesPendingService } from "../notificaciones_pending.service"
import { trigger, transition, animate, keyframes, style } from "@angular/animations"

@Component({
    selector: "notificaciones-on-navbar,[notificaciones-on-navbar]",
    templateUrl: "notificaciones-on-navbar.component.html",
    styleUrls: ["notificaciones-on-navbar.component.scss"],
    animations: [
        trigger("menu", [
            transition(":enter", [
                animate(
                    "0.2s ease-in",
                    keyframes([
                        style({ opacity: 0, transform: "translateY(-20px)", offset: 0 }),
                        style({ opacity: 1, transform: "translateY(0)", offset: 1.0 })
                    ])
                )
            ]),
            transition(":leave", [
                animate(
                    "0.2s ease-in",
                    keyframes([
                        style({ opacity: 1, transform: "translateY(0)", offset: 0 }),
                        style({ opacity: 0, transform: "translateY(-20px)", offset: 1.0 })
                    ])
                )
            ])
        ])
    ]
})
export class NotificacionesOnNavbarComponent implements OnInit {
    notificaciones: Notificacion[]
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent
    @Input() maximoNotificaciones: number = 3
    @Input() pendientes: boolean = true
    @Input() enableContentStyle: boolean = true
    openContainer: boolean = false
    @ViewChild("menu") insideElement
    catchOutsideClick: boolean = false
    enableOpen: boolean = true

    @HostListener("document:click", ["$event.target"]) public onClick(targetElement) {
        const clickedInside = this.insideElement.nativeElement.contains(targetElement)
        if (this.catchOutsideClick && !clickedInside && this.openContainer) {
            this.close()
        }
    }

    constructor(
        protected notificacionesService: Notificaciones,
        protected notificacionesPendingService: NotificacionesPendingService
    ) {}

    ngOnInit() {}

    open() {
        if (!this.openContainer) {
            this.openContainer = true
            this.enableOpen = false
            setTimeout(() => {
                this.catchOutsideClick = true
            }, 300)
        }
    }

    close() {
        this.openContainer = false
        this.catchOutsideClick = false
        setTimeout(() => {
            this.enableOpen = true
        }, 300)
    }
}
