import { Component, Input, OnInit } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "redes-sociales-showcasebox",
    templateUrl: "./redes-sociales-showcasebox.component.html",
    styleUrls: ["./redes-sociales-showcasebox.component.scss"]
})
export class RedesSocialesShowcaseboxComponent implements OnInit {
    config: typeof config
    @Input() redesSociales = []

    constructor() {}

    ngOnInit() {
        this.config = config
    }
}
