<ll-toolnavbar>
    <usuario-toolnavbar [usuario]="usuario" [displayButtons]="'all new edit'"></usuario-toolnavbar>
</ll-toolnavbar>
<ll-titulo>{{ usuario?.nombreCompleto() }}</ll-titulo>
<ll-tabs>
    <ul class="nav nav-tabs" #tabList>
        <li *ngIf="visibleTabs.length === 0 || visibleTabs.includes('perfil')"
            role="presentation" 
            [ngClass]="{ in: currentTab == 'perfil', active: currentTab == 'perfil' }">
            <a data-toggle="tab" href="#profile" (click)="setCurrentTab('perfil')">
                {{ "usuario.tabs.perfil" | t }}
            </a>
        </li>
        <li 
            *ngIf="visibleTabs.length === 0 || visibleTabs.includes('favoritos')"
            role="presentation" 
            [ngClass]="{ in: currentTab == 'favoritos', active: currentTab == 'favoritos' }">
            <a data-toggle="tab" href="#favoritos" (click)="setCurrentTab('favoritos')">
                {{ "usuario.tabs.favoritos" | t }}
            </a>
        </li>
        <li
            *ngIf="config.plataforma.enableAchievements && myUsuarioId == usuario?.id"
            role="presentation"
            [ngClass]="{ in: currentTab == 'logros', active: currentTab == 'logros' }"
        >
            <a data-toggle="tab" href="#logros" (click)="setCurrentTab('logros')">
                {{ "usuario.tabs.logros" | t }}
            </a>
        </li>
    </ul>
</ll-tabs>

<loading-layout #loadingLayout>
    <div *ngIf="!loadingLayout.loading">
        <div class="container-fluid">
            <div class="tab-content">
                <div
                    id="profile"
                    class="tab-pane fade"
                    [ngClass]="{ in: currentTab == 'perfil', active: currentTab == 'perfil' }"
                >
                    <cui-button-link
                        [routerLinkValue]="['/usuarios/editar']"
                        size="small"
                        class="pull-right edit-btn"
                        *ngIf="!isPrivate"
                    >
                        <fa name="edit"></fa>
                        <span>&nbsp;Editar cuenta</span>
                    </cui-button-link>
                    <usuario
                        [usuario]="usuario"
                        [enableShowEstablecimientoData]="enableShowEstablecimientoData"
                        [enableShowCountryData]="enableShowCountryData"
                    ></usuario>
                </div>
                <div
                    id="favoritos"
                    class="tab-pane fade"
                    [ngClass]="{ in: currentTab == 'favoritos', active: currentTab == 'favoritos' }"
                >
                    <materiales-favoritos></materiales-favoritos>
                </div>
                <div
                    *ngIf="config.plataforma.enableAchievements && myUsuarioId == usuario?.id"
                    id="logros"
                    class="tab-pane fade"
                    [ngClass]="{ in: currentTab == 'logros', active: currentTab == 'logros' }"
                >
                    <logros-perfil></logros-perfil>
                </div>
            </div>
        </div>
    </div>
</loading-layout>
