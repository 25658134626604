import { Component, OnInit, OnDestroy, ViewChild, Input, Injector, ChangeDetectorRef } from "@angular/core"
import { Subscription, combineLatest, forkJoin } from "rxjs"
import { Router, ActivatedRoute } from "@angular/router"
import { Usuario, Usuarios, UsuarioView } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { DomSanitizer } from "@angular/platform-browser"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
import { query } from "@angular/animations"
import { startWith } from "rxjs/operators"
import { coreDirectives } from "@agm/core/core.module"
import { createDate } from "ngx-bootstrap/chronos/create/date-from-array"
declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "usuario_perfil_alumno.component.html",
    styleUrls: ["usuario_perfil_alumno.component.scss"]
})
export class UsuarioPerfilAlumnoComponent implements OnInit, OnDestroy {
    usuario: Usuario
    private sub: Subscription
    params: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    openingTab: string = "perfil"
    currentTab: string = ""
    subQueryParams: Subscription
    pais: string
    idPais: any
    myUsuarioId: number
    isPrivate: boolean = true
    usuarioId: number
    config = config

    subData: Subscription
    showUsuarioPaa: boolean = false
    enableShowEstablecimientoData: boolean = true
    enableShowCountryData: boolean = true
    visibleTabs = []

    constructor(
        protected titleService: TitleService,
        protected usuariosService: Usuarios,
        public router: Router,
        public route: ActivatedRoute,
        protected sanitizer: DomSanitizer,
        protected authService: AuthService,
        protected injector: Injector,
        protected cdr: ChangeDetectorRef
    ) {
        this.myUsuarioId = this.authService.getUserData().id
        UsuarioView.injector = this.injector
        this.params = UsuarioView.viewParams
    }

    ngOnInit() {
        this.titleService.setTitle("titles.core.usuarios.usuario_perfil_alumno")
        this.pais = config.plataforma.pais
        this.idPais = config.plataforma.identificadorUsuario
        this.sub = this.route.params.subscribe(params => {
            this.usuarioId = +params["id"]
            this.usuariosService.find(this.usuarioId, { include: "usuario_" + this.pais }).then((usuario: Usuario) => {
                this.usuario = usuario
                this.isPrivate = !(this.usuario.id == this.myUsuarioId)
                this.loadingLayout.ready()
            })
            if (location.href.includes("logros")) {
                this.setCurrentTab("logros")
            }
        })
        this.subData = this.route.data.subscribe(data => {
            this.showUsuarioPaa = data.showUsuarioPaa ?? this.showUsuarioPaa
            this.enableShowEstablecimientoData =
                data.enableShowEstablecimientoData ?? this.enableShowEstablecimientoData
            this.enableShowCountryData = data.enableShowCountryData ?? this.enableShowCountryData
            this.visibleTabs = (data?.tab || "").split(",").filter(w => w.length > 0)
        })
        this.subQueryParams = this.route.queryParams.subscribe(queryParams => {
            if (!queryParams["tab"]) {
                this.router.navigate(["."], { relativeTo: this.route, queryParams: { tab: "perfil" } })
            } else {
                this.currentTab = queryParams["tab"]
                this.openingTab = this.currentTab
            }
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subData.unsubscribe()
        this.subQueryParams.unsubscribe()
    }

    setTabClasses(tab: string) {
        let classes = {
            active: this.openingTab == tab,
            in: this.openingTab == tab
        }
        return classes
    }

    setCurrentTab(tab: string) {
        this.currentTab = tab
        this.router.navigate(["."], { relativeTo: this.route, queryParams: { tab } })
    }
}
