import { Component, OnInit } from "@angular/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "instrucciones-eliminacion-datos",
    templateUrl: "./instrucciones-eliminacion-datos.component.html"
})
export class InstruccionesEliminacionDatosComponent implements OnInit {
    constructor(protected titleService: TitleService) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.landing.instrucciones_eliminacion_datos")
    }
}
