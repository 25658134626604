import { Component, ViewChild, OnInit } from "@angular/core"
import { Usuario, Usuarios } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AuthService } from "@puntaje/shared/core"
import {
    UsuarioPlataforma,
    UsuarioPlataformas,
    Nivel,
    Niveles,
    UsuarioPlataformaExperiencias,
    UsuarioPlataformaExperiencia
} from "@puntaje/achievements/api-services"

@Component({
    selector: "logros-ranking-showcasebox",
    templateUrl: "logros-ranking-showcasebox.component.html",
    styleUrls: ["logros-ranking-showcasebox.component.scss"]
})
export class LogrosRankingShowcaseboxComponent implements OnInit {
    config: typeof config
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    ranking: UsuarioPlataformaExperiencia[]
    getUsuarioRanking: UsuarioPlataforma[]
    usuarioActual: Usuario
    perPage: number = 5
    maxPage: number = 10
    currentPage: number = 0
    usuariosRanking: any
    usuarioEnRanking: any
    usuarioPosRanking: any
    nombreCompleto: any
    nivelUsuario: any
    nivelesRanking: any
    niveles: Nivel[]
    nivelesReady: boolean = false
    year: number = new Date().getFullYear()

    constructor(
        protected usuarioPlataformaExperienciasService: UsuarioPlataformaExperiencias,
        protected usuarioService: Usuarios,
        protected nivelesService: Niveles,
        protected authService: AuthService
    ) {
        this.config = config
        this.usuarioActual = this.authService.getUserData()
        this.nombreCompleto =
            this.usuarioActual.nombre +
            " " +
            this.usuarioActual.apellido_paterno +
            " " +
            this.usuarioActual.apellido_materno
    }

    ngOnInit() {
        this.nivelesService.where().then(niveles => {
            this.niveles = niveles.sort((n1, n2) => n1.experiencia - n2.experiencia)
            this.nivelesReady = true
            // this.getUsuarioActualRanking()
        })
    }

    showAll = (page: number = 1, per: number = 5) => {
        if (this.loadingLayout) this.loadingLayout.standby()

        this.usuariosRanking = null
        const params = {
            usuario_plataforma_experiencia: {
                year: this.year
            },
            plataforma: {
                plataforma: config.plataforma.name
            },
            page: page,
            per: per,
            sort_by: "experiencia",
            order: "desc",
            render_options: { include: { usuario_plataforma: null } }
        }
        this.currentPage = page

        return this.usuarioPlataformaExperienciasService
            .where(params)
            .then((usuarioPlataformaExperiencias: UsuarioPlataformaExperiencia[], total: number) => {
                const usuarioPlataformas = usuarioPlataformaExperiencias.map(upe => upe.usuario_plataforma)

                this.ranking = usuarioPlataformaExperiencias
                let users = usuarioPlataformas.map(gi => gi.usuario_id)
                const paramsUsuarios = {
                    usuario: {
                        id: users
                    }
                }
                this.getNiveles()
                return this.usuarioService.where(paramsUsuarios).then(usuarios => {
                    this.usuariosRanking = usuarios

                    this.usuariosRanking = usuarios.reduce((acc, us) => {
                        acc[us.id] = us

                        return acc
                    }, {})
                    if (this.loadingLayout) {
                        this.loadingLayout.ready()
                    }
                    return total
                })
            })
    }

    getNiveles() {
        this.nivelesRanking = []
        this.ranking.forEach(r => {
            const nivel = this.niveles.find(n => r.usuario_plataforma.experiencia < n.experiencia)
            this.nivelesRanking.push(nivel)
        })
    }

    /**
     * TODO: si se volviese a ocupar, implemetar usando RANK de mysql para evitar traer la table entera.
     * Ajustar el codigo para que se ocupe usuarioPlataformaExperiencias (antes se ocupaba )
     **/
    // getUsuarioActualRanking() {
    //     const params = {
    //         usuario_plataforma: {
    //             usuario_id: this.usuarioActual.id
    //         },
    //         plataforma: {
    //             plataforma: config.plataforma.name
    //         },
    //         sort_by: "experiencia",
    //         order: "desc"
    //     }
    //     this.usuarioPlataformasService.where(params).then((usuarioPlataformaExperiencias: UsuarioPlataforma[]) => {
    //         this.getUsuarioRanking = usuarioPlataformas
    //         this.usuarioEnRanking = this.getUsuarioRanking.find(upr => upr.usuario_id == this.usuarioActual.id)
    //         this.usuarioPosRanking = this.getUsuarioRanking.map(x => x.usuario_id).indexOf(this.usuarioActual.id)
    //         this.nivelUsuario = this.niveles.find(n => this.usuarioEnRanking?.experiencia < n.experiencia)
    //     })
    // }
}
