import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { EvaluacionInstancia, EvaluacionInstancias } from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"

@Component({
    selector: "ensayos-recientes-showcasebox",
    templateUrl: "./ensayos-recientes-showcasebox.component.html",
    styleUrls: ["./ensayos-recientes-showcasebox.component.scss"]
})
export class EnsayosRecientesShowcaseboxComponent implements OnInit {
    @Input() evaluacionTipo = this.config.plataforma.evaluacionDefault
    enableResponsive: boolean
    asignatura: Asignatura
    evaluacionInstancias: EvaluacionInstancia[] = []
    @Input() link: string = "/pruebas_de_transicion/instancias/"
    @Input() urlResultados: string = "/pruebas_de_transicion/resultados/"
    @Input() paramsResultados: any = {}

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(protected config: AppConfig, protected evaluacionInstanciasService: EvaluacionInstancias) {}

    ngOnInit() {
        this.enableResponsive = window.innerWidth <= 800
    }

    onAsignaturaChange(asignatura: Asignatura) {
        this.asignatura = asignatura
        this.cargarEnsayos()
    }

    async cargarEnsayos() {
        this.loadingLayout.standby()
        let params: any = {
            page: 1,
            per: 4,
            mios: 1,
            evaluacion_instancia: {
                asignatura_id: this.asignatura.id
            },
            evaluacion_tipo: {
                evaluacion_tipo: this.evaluacionTipo
            },
            render_options: {
                include: {
                    evaluacion: {
                        include: {
                            instrumento: null,
                            evaluacion_tipo: null,
                            evaluacion_multiples: null
                        },
                        methods: ["compartido_por"]
                    }
                }
            }
        }

        return this.evaluacionInstanciasService.where(params).then((eis: EvaluacionInstancia[], total: number) => {
            this.evaluacionInstancias = eis
            this.loadingLayout.ready()
        })
    }
}
