<ll-titulo>Participa</ll-titulo>

<div class="row">
    <div class="col-md-4 col-sm-6">
        <sub-menu [itemsMenu]="itemsMenu"></sub-menu>
    </div>
    <div class="col-md-4 col-sm-6">
        <dudas-showcase></dudas-showcase>
    </div>
    <div class="col-md-4 col-sm-6">
        <redes-sociales-showcasebox [redesSociales]="redesSociales"></redes-sociales-showcasebox>
    </div>
</div>
