<div class="component-container">
    <div class="title">{{ "practicar.showcasebox.resumen.semana" | t }}</div>
    <div class="row">
        <div class="col-md-4 col-sm-12">
            <div class="value-container">
                <div class="value">
                    <b>{{ ensayos | easyplaceholder: "-" }}</b>
                </div>
                <div class="value-footer">
                    <a [routerLink]="linkEvaluacion" [queryParams]="{ tipo_instrumento: tipoEvaluacion }">
                        {{ "practicar.showcasebox.resumen.ensayos" | t }}
                    </a>
                </div>
            </div>
        </div>
        <div class="col-md-4 col-sm-12">
            <div class="value-container">
                <div class="value">
                    <b>{{ preguntasContestadas | easyplaceholder: "-" }}</b>
                </div>
                <div class="value-footer">{{ "practicar.showcasebox.resumen.preguntas_contestadas" | t }}</div>
            </div>
        </div>
        <div class="col-md-4 col-sm-12">
            <div class="value-container">
                <div class="value">
                    <b>{{ preguntasCorrectas | easyplaceholder: "-" }}</b>
                </div>
                <div class="value-footer">{{ "practicar.showcasebox.resumen.preguntas_correctas" | t }}</div>
            </div>
        </div>
    </div>
</div>
