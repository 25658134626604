<div class="noticias">
    <div *ngFor="let noticia of noticias" class="alert alert-warning noticia" role="alert">
        <span class="noticia-inner">
            <ogr-icon class="noticia-icon" name="megafono"></ogr-icon>
            <span [innerHTML]="noticia.noticia"></span>
        </span>
        <button (click)="marcarVisto(noticia)" type="button" class="close" data-dismiss="alert" aria-label="Cerrar">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>
