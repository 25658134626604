import { Component, Input, OnInit } from "@angular/core"
import { SessionService } from "@puntaje/shared/core"
import { Establecimiento, GrupoUsuario, GrupoUsuarios } from "@puntaje/puntaje/api-services"

@Component({
    selector: "grupo-usuarios-notificaciones-showcasebox",
    templateUrl: "./grupo-usuarios-notificaciones-showcasebox.component.html",
    styleUrls: ["./grupo-usuarios-notificaciones-showcasebox.component.scss"]
})
export class GrupoUsuariosNotificacionesShowcaseboxComponent implements OnInit {
    @Input() maximoNotificaciones: number = 5
    @Input() pendientes: boolean = true
    @Input() extraActions: any[]

    grupoUsuariosByEstablecimientoId: {
        [id: number]: { nombres: string; establecimiento: Establecimiento; grupoUsuarios: GrupoUsuario[] }
    }

    constructor(protected grupoUsuariosService: GrupoUsuarios, protected sessionService: SessionService) {}

    ngOnInit() {
        const params = {
            grupo_usuario: {
                id: this.sessionService.getGrupoUsuariosActivos()
            },
            include: "[establecimiento]"
        }

        this.grupoUsuariosService.where(params).then(grupoUsuarios => {
            this.grupoUsuariosByEstablecimientoId = grupoUsuarios.reduce((acc, gu) => {
                if (gu.establecimiento_id) {
                    acc[gu.establecimiento_id] = acc[gu.establecimiento_id] || {}
                    acc[gu.establecimiento_id].grupoUsuarios = acc[gu.establecimiento_id].grupoUsuarios || []
                    acc[gu.establecimiento_id].grupoUsuarios.push(gu)
                    acc[gu.establecimiento_id].nombres = acc[gu.establecimiento_id].grupoUsuarios
                        .map(gu => gu.nombre)
                        .join(", ")
                    acc[gu.establecimiento_id].establecimiento = gu.establecimiento
                }

                return acc
            }, {})
        })
    }
}
