import { Component, OnDestroy, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Universidad } from "@puntaje/puntaje/api-services"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./universidad.component.html",
    styleUrls: ["./universidad.component.scss"]
})
export class UniversidadComponent implements OnInit, OnDestroy {
    universidadId: number
    routeSub: Subscription
    dataSub: Subscription
    universidad: Universidad

    showTitle: boolean = false

    constructor(protected titleService: TitleService, private route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.universidad.show")
        this.routeSub = this.route.params.subscribe(params => {
            this.universidadId = +params["id"]
        })

        this.dataSub = this.route.data.subscribe(data => {
            this.showTitle = data.showTitle ?? this.showTitle
        })
    }

    setUniversidad(universidad) {
        this.universidad = universidad
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe()
        this.dataSub.unsubscribe()
    }
}
