<loading-layout #loadingLayout>
    <div class="content-list-menu" *ngIf="itemsMenu.length > 0">
        <div *ngFor="let item of itemsMenu">
            <a
                class="list-menu"
                [routerLink]="item.route ? item.route : null"
                [queryParams]="item.params"
                (click)="item.fn ? item.fn() : null"
            >
                <div class="name">{{ item.name }}</div>
                <div class="content-icono"><fa name="caret-right" class="icono"></fa></div>
            </a>
        </div>
    </div>
</loading-layout>
