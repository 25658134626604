import { Component, OnInit } from "@angular/core"
import { Subscription } from "rxjs"
import { ActivatedRoute } from "@angular/router"
import {
    PlanPersonales,
    PlanPersonal,
    PlanPersonalInstancia,
    PlanPersonalInstancias
} from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "plan-personal",
    templateUrl: "./plan-personal.component.html",
    styleUrls: ["./plan-personal.component.scss"]
})
export class PlanPersonalComponent implements OnInit {
    sub: Subscription
    planPersonal: PlanPersonal
    planPersonalInstancia: PlanPersonalInstancia
    planPersonalId: number
    usuarioId: number

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected planPersonalesService: PlanPersonales,
        protected planPersonalInstanciasService: PlanPersonalInstancias,
        protected authService: AuthService
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.plan_personales.show")
        this.sub = this.route.params.subscribe(params => {
            this.planPersonalId = +params["id"]
        })
    }
}
