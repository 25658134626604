import { Component, OnInit, ViewChild } from "@angular/core"
import {
    Logro,
    LogroUsuarioPlataforma,
    LogroUsuarioPlataformas,
    Nivel,
    Niveles,
    UsuarioPlataforma,
    UsuarioPlataformas,
    UsuarioPlataformaExperiencias,
    Categorias,
    Categoria,
    Actividad,
    Actividades
} from "@puntaje/achievements/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig, AuthService } from "@puntaje/shared/core"
declare const config: AppConfig
@Component({
    selector: "logros-perfil",
    templateUrl: "./logros-perfil.component.html",
    styleUrls: ["./logros-perfil.component.scss"]
})
export class LogrosPerfilComponent implements OnInit {
    niveles: Nivel[]
    usuarioPlataforma: UsuarioPlataforma

    year = new Date().getFullYear()
    experiencia_year = 0

    nivel: Nivel
    nextNivel: Nivel

    loadedNiveles: boolean = false
    loadedUsuarioPlataforma: boolean = false

    experienciaNivelPer: number = 0

    limitUltimosLogros = 3
    ultimosLogros: LogroUsuarioPlataforma[] = []
    actividades: Actividad[] = []
    categoriasWithLogros: Categoria[] = []
    logroUsuarioPlataformasByLogroId: { [key: number]: LogroUsuarioPlataforma } = {}
    countLogrosCompletadosByCategoria: { [key: number]: number } = {}

    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent

    constructor(
        protected authService: AuthService,
        protected nivelesService: Niveles,
        protected usuarioPlataformasService: UsuarioPlataformas,
        protected logroUsuarioPlataformasService: LogroUsuarioPlataformas,
        protected actividadesService: Actividades,
        protected categoriasService: Categorias,
        protected usuarioPlataformaExperienciasService: UsuarioPlataformaExperiencias
    ) {}

    ngOnInit() {
        this.usuarioPlataformaExperienciasService
            .where({
                plataforma: { plataforma: config.plataforma.name },
                usuario_plataforma: { usuario_id: this.authService.getUserData().id },
                usuario_plataforma_experiencia: { year: this.year }
            })
            .then(experiencias => {
                experiencias.forEach(experiencia => {
                    this.experiencia_year = experiencia.experiencia
                    this.year = experiencia.year
                })
            })
        this.nivelesService.where({ plataforma: { plataforma: config.plataforma.name } }).then(niveles => {
            this.niveles = niveles.sort((n1, n2) => n1.experiencia - n2.experiencia)

            this.loadedNiveles = true

            const params = {
                usuario_plataforma: {
                    usuario_id: this.authService.getUserData().id
                },
                plataforma: {
                    plataforma: config.plataforma.name
                }
            }

            this.usuarioPlataformasService.where(params).then(usuarioPlataformas => {
                this.usuarioPlataforma = usuarioPlataformas[0]
                this.loadedUsuarioPlataforma = true

                this.setNivelUsuario()
                this.setUltimosLogros()
                this.setLogrosByCategoria()
            })
            const params2 = {
                actividad: {
                    activo: 1
                },
                plataforma: {
                    plataforma: config.plataforma.name
                }
            }
            this.actividadesService.where(params2).then(actividades => {
                this.actividades = actividades
            })
        })
    }

    setNivelUsuario() {
        if (this.loadedNiveles && this.loadedUsuarioPlataforma) {
            const experiencia = this.usuarioPlataforma?.experiencia || 0

            const nextNivelIndex = this.niveles.findIndex(n => experiencia < n.experiencia)
            if (nextNivelIndex) {
                this.nivel = this.niveles[nextNivelIndex - 1]
                this.nextNivel = this.niveles[nextNivelIndex]

                this.experienciaNivelPer = (100 * experiencia) / (this.nextNivel.experiencia - this.nivel?.experiencia)
            } else {
                this.experienciaNivelPer = 100
            }
            this.loadingLayout.ready()
        }
    }

    setUltimosLogros() {
        const paramsUltimosLogros = {
            logro_usuario_plataforma: { usuario_plataforma_id: this.usuarioPlataforma.id },
            page: 1,
            per: this.limitUltimosLogros,
            sort_by: "created_at",
            order: "desc",
            render_options: { include: { logro: null } }
        }

        this.logroUsuarioPlataformasService.where(paramsUltimosLogros).then(logroUsuarioPlataformas => {
            this.ultimosLogros = logroUsuarioPlataformas
        })
    }

    setLogrosByCategoria() {
        const paramsCategoriasLogros = {
            plataforma_name: config.plataforma.name,
            render_options: { include: { logros: null } }
        }

        const categoriasPromise = this.categoriasService.where(paramsCategoriasLogros)

        const paramsLogrosUsuario = {
            logro_usuario_plataforma: { usuario_plataforma_id: this.usuarioPlataforma.id }
        }

        const logroUsuarioPlatPromise = this.logroUsuarioPlataformasService.progreso(paramsLogrosUsuario)
        Promise.all([categoriasPromise, logroUsuarioPlatPromise]).then(([categorias, logroUsuarioPlataformas]) => {
            categorias ||= []
            logroUsuarioPlataformas ||= [] as any
            this.categoriasWithLogros = categorias
            this.logroUsuarioPlataformasByLogroId = logroUsuarioPlataformas.reduce((acc, logroUsuarioPlataforma) => {
                acc[logroUsuarioPlataforma.logro_id] = logroUsuarioPlataforma
                return acc
            }, {})

            this.countLogrosCompletadosByCategoria = categorias.reduce((acc, categoria) => {
                acc[categoria.id] = categoria.logros.filter(
                    logro => !!this.logroUsuarioPlataformasByLogroId[logro.id]?.usuario_plataforma_id
                ).length

                return acc
            }, {})
        })
    }
}
