import { Component, Input, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AuthService, CapitalizePipe } from "@puntaje/shared/core"
import { CarreraSedes, CarreraSede, Universidad, Universidades } from "@puntaje/carreras/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "universidades-listado",
    templateUrl: "universidades-listado.component.html",
    styleUrls: ["universidades-listado.component.scss"]
})
export class UniversidadesListadoComponent {
    _universidades: Universidad[]
    @Input() numUniversidades: number = 4
    @Input()
    set universidades(items) {
        this._universidades = items
        this.getUniversidades()
    }
    get universidades() {
        return this._universidades
    }
    universidadIds: number[]

    @ViewChild(LoadingLayoutComponent, { static: true }) loadingLayout: LoadingLayoutComponent

    capitalize = new CapitalizePipe()

    constructor(protected authService: AuthService, protected universidadesService: Universidades) {}

    ngOnInit() {
        this.getUniversidades()
    }

    getUniversidades() {
        this.loadingLayout.standby()

        if (this.universidades) {
            this.universidadIds = this.universidades.map(u => u.id)
            this.loadingLayout.ready()
        } else {
            const params = {
                count: this.numUniversidades,
                pais: { pais: this.capitalize.transform(config.plataforma.pais) },
                include: "[sedes:[lugar:[pais]]]"
            }

            this.universidadesService.getRandom(params).then(res => {
                this.universidades = res
                this.universidadIds = res.map(u => u.id)
                this.loadingLayout.ready()
            })
        }
    }
}
