import { Component, Input, OnInit, ViewChild, SimpleChanges, OnChanges, ChangeDetectorRef } from "@angular/core"

import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import {
    Asignatura,
    Asignaturas,
    TipoInstrumento,
    TipoInstrumentos,
    GeneradorInstrumento
} from "@puntaje/nebulosa/api-services"
import {
    EvaluacionInstancia,
    EvaluacionInstancias,
    InstrumentoMensual,
    InstrumentoMensuales,
    Usuario
} from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "instrumento-mensual-ranking",
    templateUrl: "instrumento_mensual_ranking.component.html",
    styleUrls: ["instrumento_mensual_ranking.component.scss"]
})
export class InstrumentoMensualRankingComponent implements OnInit, OnChanges {
    config: typeof config
    @Input() asignatura: Asignatura
    @Input() tipoInstrumentoId: number
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("loadingLayoutInside", { static: true }) loadingLayoutInside: LoadingLayoutComponent
    @Input() month: number = new Date().getMonth()
    @Input() year: number = new Date().getFullYear()
    evaluacion_id: number
    paginatorActive = false
    evaluacionesInstancia: EvaluacionInstancia[]
    currentPage: number = 0
    maxScore: number = 0
    usuario: Usuario
    evaluacionInstanciaRanked: EvaluacionInstancia
    totalParticipacion: number = 0
    perPage: number = 5
    @Input() generadorInstrumento: GeneradorInstrumento

    constructor(
        private evaluacionInstanciaService: EvaluacionInstancias,
        private instrumentoMensualService: InstrumentoMensuales,
        private authService: AuthService,
        private cdr: ChangeDetectorRef
    ) {
        this.config = config
        this.usuario = this.authService.getUserData()
    }

    ngOnInit() {
        //this.getInfo();
    }

    getInfo() {
        this.loadingLayout.standby()
        this.evaluacionInstanciaRanked = null
        this.paginatorActive = false
        let queryMensual = {
            instrumento_mensual: {
                asignatura_id: this.asignatura.id,
                month: this.month,
                year: this.year,
                tipo_instrumento_id: this.tipoInstrumentoId
            }
        }
        if (this.generadorInstrumento) {
            queryMensual["instrumento"] = {}
            queryMensual["instrumento"]["generador_instrumento_id"] = this.generadorInstrumento.id
        }
        this.instrumentoMensualService.where(queryMensual).then((instrumentosMensuales: InstrumentoMensual[]) => {
            if (instrumentosMensuales.length > 0) {
                this.evaluacion_id = instrumentosMensuales[0].evaluacion_usuario.evaluacion_id
                //this.showAll(1, 1);
                this.getRanking()
                this.paginatorActive = true
            } else {
                this.loadingLayout.ready()
                if (this.loadingLayoutInside) this.loadingLayoutInside.ready()
            }
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        this.evaluacionesInstancia = []
        this.getInfo()
    }

    showAll = (page: number, per: number) => {
        if (this.loadingLayoutInside) this.loadingLayoutInside.standby()
        const queryEvaluacion = {
            evaluacion_instancia: {
                evaluacion_id: this.evaluacion_id,
                oficial: 1
            },
            sort: [
                { field: "calificacion", criteria: "desc" },
                { field: "tiempo_ocupado", criteria: "asc" },
                { field: "created_at", criteria: "asc" }
            ],
            methods: "[usuario_simple]",
            page: page,
            per: per
        }
        this.currentPage = page
        return this.evaluacionInstanciaService
            .where(queryEvaluacion)
            .then((evalInstancias: EvaluacionInstancia[], total: number) => {
                this.evaluacionesInstancia = evalInstancias
                if (this.evaluacionesInstancia.length > 0 && page == 1) {
                    this.maxScore = this.evaluacionesInstancia[0].calificacion
                }
                this.totalParticipacion = total
                if (this.loadingLayoutInside) this.loadingLayoutInside.ready()
                return total
            })
    }

    getRanking() {
        this.evaluacionInstanciaRanked = null
        const queryEvaluacion = {
            evaluacion_instancia: {
                evaluacion_id: this.evaluacion_id,
                oficial: 1
            },
            ranking: {
                usuario_id: this.usuario.id,
                oficial: 1
            },
            sort: [
                { field: "calificacion", criteria: "desc" },
                { field: "tiempo_ocupado", criteria: "asc" },
                { field: "created_at", criteria: "asc" }
            ],
            methods: "[usuario_simple]"
        }
        this.evaluacionInstanciaService.getRanked(queryEvaluacion).then((evalInstancias: EvaluacionInstancia[]) => {
            if (evalInstancias.length > 0) this.evaluacionInstanciaRanked = evalInstancias[0]
            this.loadingLayout.ready()
        })
    }
}
