import { Component, Input, ViewChild, OnInit } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { PaginatorComponent, CapitalizePipe } from "@puntaje/shared/core"
import { ActivatedRoute, Router } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Subscription } from "rxjs"
import { Sede, Sedes, Universidad, Universidades } from "@puntaje/carreras/api-services"
import { ChangeDetectorRef } from "@angular/core"
import {
    CUIInputsModule,
    CUITextsModule,
    CUITablesModule,
    CUIContainersModule,
    CUILayoutsModule,
    CUISectionHeadlineComponent
} from "@puntaje/shared/cui"

@Component({
    selector: "buscador-universidades",
    templateUrl: "buscador-universidades.component.html",
    styleUrls: ["buscador-universidades.component.scss"]
})
export class BuscadorUniversidadesComponent implements OnInit {
    @ViewChild(LoadingLayoutComponent, { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent) paginator: PaginatorComponent
    @ViewChild(CUISectionHeadlineComponent) cuiSectionHeadline: CUISectionHeadlineComponent
    @Input() withPais = false
    @Input() universidadId: number

    universidades: Universidad[]
    sedes: Sede[]
    showSedes: boolean = false
    capitalize = new CapitalizePipe()
    queryUniversidad: string = ""
    universidad: Universidad | string
    sede: Sede | string
    querySede: string = ""
    queryCarrera: string = ""
    filteredUniversidades: Universidad[] = []
    universidadInput: Universidad | string
    query: boolean = false
    firstLoad = true

    milisecondsTime = new Date().getTime()

    sub: Subscription

    constructor(
        public universidadesService: Universidades,
        public router: Router,
        protected route: ActivatedRoute,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.sub = this.route.queryParams.subscribe(params => {
            this.universidad = this.queryUniversidad = params["universidad"] || ""
            if (this.firstLoad) {
                this.firstLoad = false
            } else {
                this.setData(1, 10)
            }

            this.query = true
        })
    }

    getAllUniversidades() {
        const params: any = {}
        if (this.withPais) {
            params["pais"] = this.capitalize.transform(config.plataforma.pais)
        }
        if (config.registro?.universidades && config.registro.universidades.length > 0) {
            params["universidad"] = config.registro.universidades
        }
        this.universidadesService.where(params).then((universidades: Universidad[]) => {
            this.universidades = universidades
        })
    }

    updateSearch() {
        const queryUniversidad = this.universidad ? this.universidad.toString() : ""

        const queryParams: any = {}

        if (queryUniversidad) {
            queryParams.universidad = queryUniversidad
        }

        this.router.navigate([], { queryParams })
    }

    setData = (page: number, per: number) => {
        this.firstLoad = false
        const params: any = {
            include: "[sedes:[lugar:pais]]",
            pais: { pais: this.capitalize.transform(config.plataforma.pais) },
            page,
            per
        }

        if (this.queryUniversidad) {
            params.universidad = { like: { universidad: `%${this.queryUniversidad}%` } }
        }

        return this.universidadesService.where(params).then((universidades, total) => {
            this.universidades = universidades
            this.cdr.detectChanges()
            return total
        })
    }

    clearSearch() {
        this.query = null
        this.universidades = null
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe()
    }
}
