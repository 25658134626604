import { Input } from "@angular/core"
import { Component, OnInit } from "@angular/core"
import { Logro } from "@puntaje/achievements/api-services"

@Component({
    selector: "logro",
    templateUrl: "./logro.component.html",
    styleUrls: ["./logro.component.scss"]
})
export class LogroComponent implements OnInit {
    @Input() logro: Logro
    @Input() completado: boolean = false
    @Input() fechaCompletado: string
    @Input() versionMini: boolean = false
    @Input() valorProgreso: number
    @Input() valorCompletado: number

    constructor() {}

    ngOnInit() {}
}
